import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, DataGrid, PieChart, Toolbar, DateBox, SelectBox, DropDownBox, List } from "devextreme-react";
import { Chart, Series, Legend, Label, Format, Title, CommonSeriesSettings, Tooltip, ArgumentAxis, ValueAxis, Export } from 'devextreme-react/chart';
import { useLoad, useToast } from "../../../../contexts";
import './incidents-analysis.scss';
import { Item } from "devextreme-react/toolbar";
import { ICTCInfo, IChaReportInfo, ICyberHazard, IInspectionDetails, IPreworkInspection, IncidentStatus, IncidentType, JobType } from "../../../../interfaces";
import { reportsService } from "../../../../api/reports";
import { Column, DataGridTypes, MasterDetail, Pager, Paging } from "devextreme-react/cjs/data-grid";
import { cyberHazardsService, inspectionService } from "../../../../api";
import moment from "moment";
import { erpService } from "../../../../api/common/ErpService";
import { IIncidentReportInfo } from "../../../../interfaces/reports/IIncidentReportInfo";

export default function IncidentsAnalysis() {
    const { showErrorToast } = useToast();
    const { showLoader } = useLoad();
    const [incidentReportData, setIncidentReportData] = useState<IIncidentReportInfo[]>([]);
    const [overallStatsByStatus, setOverallStatsByStatus] = useState<{ status: string, count: number }[]>([]);
    const [overallStatsByCategory, setOverallStatsByJobType] = useState<{ category: string, count: number }[]>([]);

    const [startDate, setStartDate] = useState<Date>(new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000)); // Default to 90 days
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [presetDateRange, setPresetDateRange] = useState<string>('Last 90 days');
    const [ctcList, setCtcList] = useState<ICTCInfo[]>([]);
    const [selectedCtcs, setSelectedCtcs] = useState<string[]>([]);

    const fetchIncidnetReportData = useCallback(async () => {
        showLoader(true);
        try {
            const response = await reportsService.getIncidentsReportsInfo(
                moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
                moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
                selectedCtcs.join(',')
            );
            if (response.success) {
                const data = response.data;
                setIncidentReportData(data);

                // Calculate overall pass/fail counts
                const closed = data.filter(item => item.status === IncidentStatus.CLOSED).length;
                const investigation = data.filter(item => item.status === IncidentStatus.UNDER_INVESTIGATION).length;
                const reported = data.filter(item => item.status === IncidentStatus.REPORTED).length;
                setOverallStatsByStatus([
                    { status: 'Closed', count: closed },
                    { status: 'Under Investigation', count: investigation },
                    { status: 'Reported', count: reported }
                ]);
                // Calculate overall category counts
                const general = data.filter(item => item.category === IncidentType.GENERAL).length;
                const vehicle = data.filter(item => item.category === IncidentType.VEHICLE).length;
                setOverallStatsByJobType([
                    { category: 'General', count: general },
                    { category: 'Vehicle', count: vehicle }
                ]);
            } else {
                console.error(response.errors);
                showErrorToast('Error fetching inspection data');
            }
        } catch (error) {
            console.error(error);
            showErrorToast('Network or server error');
        } finally {
            showLoader(false);
        }
    }, [showLoader, showErrorToast, startDate, endDate, selectedCtcs]);

    const fetchCtcs = useCallback(async () => {
        try {
            const response = await erpService.getAllCTCs();
            if (response.success) {
                setCtcList(response.data);
            } else {
                console.error(response.errors);
                showErrorToast('Error fetching CTCs');
            }
        } catch (error) {
            console.error(error);
            showErrorToast('Network or server error');
        } finally {
        }
    }, [showLoader, showErrorToast]);
    const refreshReports = useCallback(() => {
        fetchIncidnetReportData();
    }, [fetchIncidnetReportData]);

    useEffect(() => {
        refreshReports();
    }, [refreshReports]);
    useEffect(() => {
        fetchCtcs();
    }, [fetchCtcs]);
    

    const handlePresetDateRangeChange = useCallback((e: any) => {
        const value = e.value;
        setPresetDateRange(value);
        const today = new Date();
        switch (value) {
            case 'Last 1 day':
                setStartDate(new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000));
                setEndDate(today);
                break;
            case 'Last 7 days':
                setStartDate(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000));
                setEndDate(today);
                break;
            case 'Last 30 days':
                setStartDate(new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000));
                setEndDate(today);
                break;
            case 'Last 90 days':
                setStartDate(new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000));
                setEndDate(today);
                break;
            case 'Last 12 months':
                setStartDate(new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()));
                setEndDate(today);
                break;
            default:
                setStartDate(new Date(new Date().getTime() - 90* 24 * 60 * 60 * 1000));
                setEndDate(new Date());
                break;
        }
    }, []);



    const onSelectedCtcChange = useCallback((e:any) => {
        if (e.name === 'selectedItemKeys') {
          setSelectedCtcs(e.value)
        }
      }, [setSelectedCtcs]);
    return (
        <div className='inspection-analysis-container'>
            <Toolbar className='section-toolbar'>
                <Item
                    location='after'
                    locateInMenu='auto'
                    widget='dxDateBox'
                    options={{
                        value: startDate,
                        onValueChanged: (e: any) => setStartDate(e.value),
                        placeholder: 'Start Date'
                    }}
                />
                <Item
                    location='after'
                    locateInMenu='auto'
                    widget='dxDateBox'
                    options={{
                        value: endDate,
                        onValueChanged: (e: any) => setEndDate(e.value),
                        placeholder: 'End Date'
                    }}
                />
                <Item
                    location='after'
                    locateInMenu='auto'
                    widget='dxSelectBox'
                    options={{
                        items: ['Last 1 day', 'Last 7 days', 'Last 30 days', 'Last 90 days', 'Last 12 months'],
                        value: presetDateRange,
                        onValueChanged: handlePresetDateRangeChange,
                        placeholder: 'Select Date Range'
                    }}
                />
                <Item
                    location='after'
                    locateInMenu='auto'
                    render={() => (
                        <DropDownBox
                            dataSource={ctcList}
                            valueExpr="name"
                            displayExpr="name"
                            value={selectedCtcs}
                            placeholder="Select CTCs"
                            width="100%"
                        >
                            <List
                                searchEnabled
                                searchExpr="name"
                                dataSource={ctcList}
                                keyExpr="name"
                                displayExpr="name"
                                selectedItemKeys ={selectedCtcs}
                                selectionMode="all"
                                showSelectionControls
                                selectByClick
                                selectAllMode={'allPages'}
                                onOptionChanged={onSelectedCtcChange}
                            />
                        </DropDownBox>
                    )}
                />
                <Item
                    location='after'
                    locateInMenu='auto'
                    widget='dxButton'
                    showText='inMenu'
                >
                    <Button
                        text='Refresh'
                        icon='refresh'
                        stylingMode='text'
                        onClick={refreshReports}
                    />
                </Item>
            </Toolbar>
            <div className="report-section" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '50%' }}>
                    <PieChart id="overall-complete-incomplete" dataSource={overallStatsByStatus} title="Overall Counts by Status">
                        <Series argumentField="status" valueField="count">
                            <Label visible={true}>
                                <Format type="fixedPoint" precision={0} />
                            </Label>
                        </Series>
                        <Legend visible={true} />
                        <Export enabled />
                    </PieChart>
                </div>
                <div style={{ width: '50%' }}>
                    <PieChart id="overall-jobtypes" dataSource={overallStatsByCategory} title="Overall Counts by Category">
                        <Series argumentField="category" valueField="count">
                            <Label visible={true}>
                                <Format type="fixedPoint" precision={0} />
                            </Label>
                        </Series>
                        <Legend visible={true} />
                        <Export enabled />
                    </PieChart>
                </div>
                
            </div>
        </div>
    );
}

