import React, { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import "./UserPanel.scss";
import type { UserPanelProps } from "../../types";
import { useAuth0 } from "@auth0/auth0-react";

export default function UserPanel({ menuMode }: UserPanelProps) {
  const { user, logout } = useAuth0();
  const handleLogout = useCallback(() => {
    localStorage.clear();
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }, [logout]);
  const menuItems = useMemo(
    () => [
      {
        text: "Logout",
        icon: "runner",
        onClick: handleLogout,
      },
    ],
    [logout]
  );
  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        <div className={"image-container"}>
          <div
            style={{
              background: `url(${user!.picture}) no-repeat #fff`,
              backgroundSize: "cover",
            }}
            className={"user-image"}
          />
        </div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position
            my={{ x: "center", y: "top" }}
            at={{ x: "center", y: "bottom" }}
          />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}
    </div>
  );
}
