import React, { useCallback, useEffect, useState } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Tabs from 'devextreme-react/tabs';
import Button from 'devextreme-react/button';
import './Header.scss';
import { Template } from 'devextreme-react/core/template';
import type { HeaderProps } from '../../types';
import logo from '../../images/logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { SelectionChangedEvent } from 'devextreme/ui/tabs';
import RegionDialog from '../../dialogs/region-dialog/region-dialog';
import { IRegion } from '../../interfaces'
import { locationService } from '../../api';
import { useRegion, useToast } from '../../contexts';
import UserPanel from '../user-panel/UserPanel';
import { useAuth0 } from '@auth0/auth0-react';

const tabsIconAndText = [
  {
    id: 0,
    text: 'JHA',
    icon: 'like',
    path:'/jha'
  },
  {
    id: 1,
    text: 'CHA',
    icon: 'taskcomplete',
    path:'/cha'
  },
  {
    id: 2,
    text: 'Inspections',
    icon: 'taskstop',
    path:'/inspections'
  },
  {
    id: 3,
    text: 'Incidents',
    icon: 'taskhelpneeded',
    path:'/incidents'
  },
  {
    id: 4,
    text: 'Admin',
    icon: 'preferences',
    path:'/admin'
  }  
];

export default function Header({ menuToggleEnabled, title, toggleMenu }: HeaderProps) {
  const navigate = useNavigate();
  const { region,setRegion, fetchRegions, regions, isLoading } = useRegion(); // Use the region value from context
  const location = useLocation();
  const currentPath = location.pathname;
  const [selectedTab, setSelectedTab] = useState(tabsIconAndText.findIndex(tab => tab.path === currentPath));
  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    if(isAuthenticated)
      fetchRegions();
  }, [isAuthenticated]);
  useEffect(() => {
    const tabIndex = tabsIconAndText.findIndex(tab => tab.path === currentPath);
    setSelectedTab( tabIndex);
    if(!region) setIsRegionDialogVisible(true);

  }, [currentPath,region]);
  const onNavigationChanged = useCallback((args:SelectionChangedEvent) => {
    navigate(args.addedItems[0]?.path ??'');
  }, [navigate]);
  const [isRegionDialogVisible, setIsRegionDialogVisible] = useState(false);

  const toggleRegionDialog = () => {
    if(!isRegionDialogVisible){
      fetchRegions()
    }
    setIsRegionDialogVisible(!isRegionDialogVisible);
  };

  const handleApplyRegion = (region: Number | null) => {
    setRegion(region?.toString() || '');
    //setSelectedRegion(region || null);
  };
  return (
    <header id='i-safe-header' className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          location={'before'}
          cssClass={'header-title'}
          visible={true}
        >
          <img src={logo} alt="Header Logo" style={{ height: '100%', marginLeft: '20px' }} />
        </Item>
        <Item
          location={'before'}
          visible={true}
        >
          <Tabs
            id="admin-nav-menu"
            width={'auto'}
            defaultSelectedIndex={0}
            dataSource={tabsIconAndText}
            orientation={'horizontal'}
            iconPosition={'start'}
            stylingMode={'secondary'}
            selectedIndex={selectedTab}
            onSelectionChanged={onNavigationChanged}
          />
        </Item>
        <Item
          location={'after'}
          visible={true}
          cssClass='region-dialog'
        >
           <Button
            icon={'chevrondown'}
            text={!!region ? regions.find(r => r.id == Number(region))?.name :'Select Region'}
            onClick={toggleRegionDialog}
            rtlEnabled={true}
          />
        </Item>
        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
      {isRegionDialogVisible && <RegionDialog
        visible={isRegionDialogVisible}
        onHiding={toggleRegionDialog}
        onApply={handleApplyRegion}
        regions={regions}
        activeRegion={Number(region)}
        loading={isLoading}
      />}
    </header>
  )
}
