import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";

interface AuthGuardProps {
  component: React.ComponentType<any>;
  isAuthorized: boolean;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ component: Component, isAuthorized }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && isAuthorized) {
      localStorage.setItem("lastVisitedUrl", location.pathname);
    }
  }, [isAuthenticated, isAuthorized, location.pathname]);
  
  if (!isAuthenticated) {
    loginWithRedirect({
      appState: { returnTo: location.pathname }
    });
    return null;
  }
  
  if (isAuthenticated && !isAuthorized) {
    return <Navigate to="/not-authorized" />;
  }
  
  return <Component />;
};

export default AuthGuard;