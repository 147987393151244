// CustomTabPanel.tsx
import React, { useCallback, useState } from 'react';
import TabPanel from 'devextreme-react/tab-panel';

interface TabItem {
  id: number;
  title: string;
  icon: string;
  component: React.ComponentType;
}

interface CustomTabPanelProps {
  tabs: TabItem[];
}

const CustomTabPanel: React.FC<CustomTabPanelProps> = ({ tabs }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleSelectionChanged = (e: any) => {
    setSelectedTabIndex(e.component.option('selectedIndex'));
  };

  // Dynamically determine the ActiveComponent based on the selected tab index
  const item = () => {
    const SpecificComponent = tabs[selectedTabIndex].component;
    return <SpecificComponent />;
  };


  return (
    <div className="widget-container">
      <TabPanel
        key={tabs[selectedTabIndex].title}
        className="dx-theme-background-color"
        width="100%"
        height={'100%'}
        animationEnabled={true}
        dataSource={tabs}
        tabsPosition={'left'}
        stylingMode={'secondary'}
        iconPosition={'start'}
        selectedIndex={selectedTabIndex}
        onSelectionChanged={handleSelectionChanged}
        itemRender={item}
      />
     
    </div>
    
  );
};

export default CustomTabPanel;
