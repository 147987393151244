import React, { useCallback, useEffect, useState } from 'react';
import './activities.scss';
import { DataGrid, Column, Paging, Pager, SearchPanel, DataGridTypes, Selection } from 'devextreme-react/data-grid';
import { Button, TagBox, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar'
import AddActivitiesDialog from './dialogs/add-activities';
import { ISafeConfirmationButton } from '../../../../components/Customizatons';
import { IPreworkInspection, IActivity, IJobHazard, IApiResponse } from '../../../../interfaces';
import { useLoad, useToast } from '../../../../contexts';
import { activityService, inspectionService, jobHazardsService } from '../../../../api';


const Activities: React.FC = () => {
  const { showLoader } = useLoad();
  const { showErrorToast,showSuccessToast } = useToast();
  const [isAddActivitiesDialogVisible, setIsAddActivitiesDialogVisible] = useState(false);
  const [currentSelectedActivity, setCurrentSelectedActivity] = useState<IActivity | null>(null);
  const [activities, setActivities] = useState<IActivity[]>([]);
  const toggleActivitiesDialog = useCallback(() => {
    setIsAddActivitiesDialogVisible(prevState => !prevState);
  }, []);
  const [jobHazards, setJobHazards] = useState<IJobHazard[]>([]);
  const [preworkInspections, setPreworkInspections] = useState<IPreworkInspection[]>([]);
  const fetchActivities = useCallback(async () => {
    showLoader(true);
    try {
      const response = await activityService.getAllByRegion();
      if (response.success) {
        setActivities(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching activities');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, []);
  const fetchJobHazards = useCallback(async () => {
    try {
      const response = await jobHazardsService.getAllByRegion({isActive:true});
      if (response.success) {
        setJobHazards(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Job Hazards');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } 
  }, []);
  const fetchPreWorkInspections = useCallback(async () => {
    try {
      const response = await inspectionService.getAllByRegion({isActive:true});
      if (response.success) {
        setPreworkInspections(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Pre-Work Inspections');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } 
  }, []);
  useEffect(() => {
    fetchActivities();
    fetchJobHazards();
    fetchPreWorkInspections();
  }, []);
  const addNewActivity = () => {
    setCurrentSelectedActivity(null);
    setIsAddActivitiesDialogVisible(!isAddActivitiesDialogVisible);
  };

  const handleAddEditActivity = useCallback(async (activity: IActivity) => {
    showLoader(true);
    try {
      let response: IApiResponse<IActivity>;
      if (activity.id > 0) {
        // Update existing activity
        response = await activityService.update(activity.id.toString(), activity);
        if (response.success) {
          setActivities(currentActivities => 
            currentActivities.map(a => 
              a.id === activity.id ? response.data : a // Update the specific activity
            )
          );
          showSuccessToast('Activity updated successfully!');
          setIsAddActivitiesDialogVisible(false);
          fetchActivities();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to update the activity: ${response.errors.join('; ')}`);
        }
      } else {
        // Create new activity
        response = await activityService.create(activity);
        if (response.success) {
          setActivities(currentActivities => [...currentActivities, response.data]); // Add new activity
          showSuccessToast('Activity added successfully!');
          setIsAddActivitiesDialogVisible(false);
          fetchActivities();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to add the activity: ${response.errors.join('; ')}`);
        }
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast, showSuccessToast]);
  
  const handleDeleteActivity = useCallback((activityId: number) => {
    showLoader(true);
    activityService.delete(activityId.toString()).then(response => {
      if (response.success) {
        // Remove the activity from the list
        setActivities(currentActivities => 
          currentActivities.filter(activity => activity.id !== activityId)
        );
        showSuccessToast('Activity deleted successfully!');
        fetchActivities();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to delete the activity: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast]);
  
  const handleActivateActivity = useCallback((activityId: number, isActive:boolean) => {
    showLoader(true);
    activityService.activate(activityId.toString(),!isActive).then(response => {
      if (response.success) {
        setActivities(currentActivities => 
          {
            var activityMatch = currentActivities.find(a => a.id==activityId);
            if(activityMatch) activityMatch.isActive =  !activityMatch?.isActive
            return currentActivities;
          }
        );
        showSuccessToast(`Activity ${isActive ?'Disabled':'Enabled'} successfully!`);
        fetchActivities();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to Enable/Disable the activity: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast,activities]);
  const renderTitle = useCallback(() => {
    return <p className='section-title'><b>Activities</b></p>;
    }, []);
  const addButtonOptions = {
    icon: 'add',
    text: 'Add New',
    stylingMode: "outlined",
    onClick: addNewActivity
  }


  const onCellClick = useCallback((data: DataGridTypes.CellClickEvent) => {
    setCurrentSelectedActivity(data.data);
  }, []);
  const cellRender = (data: DataGridTypes.ColumnCellTemplateData) => {
    return <div>
      <Button
        text="Edit"
        type="default"
        stylingMode="text"
        onClick={() => {
          setCurrentSelectedActivity(data.data);
          toggleActivitiesDialog();
        }}
        disabled={!data.data.isActive}
      />
      <ISafeConfirmationButton
        text={data.data.isActive ? 'Disable': 'Enable'}
        type="default"
        stylingMode="text"
        componentName={Activities.name}
        item={data.data}
        onConfirm={() => handleActivateActivity(data.data.id,data.data.isActive)}

      />
      <ISafeConfirmationButton
        text="Delete"
        type="default"
        componentName={Activities.name}
        stylingMode="text"
        item={data.data}
        onConfirm={() => handleDeleteActivity(data.data.id)}
      />
      
    </div>
  };
  const jobHazardsCellRender = useCallback((data: DataGridTypes.ColumnCellTemplateData) => {
    return <TagBox
      dataSource={jobHazards}
      inputAttr={{ 'aria-label': 'JobHazard' }}
      displayExpr="name"
      valueExpr="id"
      defaultValue={data.value}
      disabled
      placeholder=''
    />
  },[jobHazards]);
  const rowPrepared = useCallback((e: DataGridTypes.RowPreparedEvent) => {
    if (e.rowType === "data") {
      if (!e.data.isActive) {
        e.rowElement.classList.add("inactive-row");
      }
    }
  }, []);
  return (<>
    <div className='activities-container'>
      <Toolbar className='section-toolbar'>
        <Item
          location='before'
          render={renderTitle}
        ></Item>
        <Item
          location='after'
          widget="dxButton"
          options={addButtonOptions}
        ></Item>
      </Toolbar>
      <DataGrid
        dataSource={activities}
        keyExpr="id"
        showBorders={true}
        allowColumnReordering={true}
        height={'80vh'}
        onCellClick={onCellClick}
        onRowPrepared={rowPrepared}
        >
        <Selection mode="single" />
        <Paging defaultPageSize={10} />
        <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showNavigationButtons displayMode='compact' showInfo={true} />

        <Column dataField="name" caption="Name" />
        <Column dataField="jobHazards" allowSearch caption="Job Hazards"
          cellRender={jobHazardsCellRender}
        />
        <Column dataField="createdDate" caption="Created Date" dataType="date" format={"dd MMM yyyy"} />

        <Column cssClass='actions-column' caption="Actions" cellRender={cellRender}>

        </Column>
        <SearchPanel placeholder={'Search here ...'} width={'auto'} visible={true} searchVisibleColumnsOnly />
      </DataGrid>
    </div>
    {isAddActivitiesDialogVisible && <AddActivitiesDialog
      visible={isAddActivitiesDialogVisible}
      onHiding={toggleActivitiesDialog}
      onApply={handleAddEditActivity}
      activity={currentSelectedActivity}
      jobHazardsDataSource={jobHazards}
      preworkInspectionsDataSource={preworkInspections}
    />
    }
    
  </>
  );
};

export default Activities;
