import './jha.scss';
import Ppe from './pages/ppe/ppe';
import HseAcknowledgement from './pages/hse-acknowledgement/hse-acknowledgement';
import JobHazards from './pages/job-hazards/job-hazards';
import SiteConditions from './pages/site-conditions/site-conditions';
import JhaAnalysis from './pages/jha-analysis/jha-analysis';
import Activities from './pages/activities/activities';
import { ISafeTabPanel } from '../../components/Customizatons';
const jhaTabs = [
    { id: 0, title: 'Activities', icon: 'mediumiconslayout', component: Activities },
    { id: 1, title: 'PPE', icon: 'taskcomplete', component: Ppe },
    { id: 2, title: 'Site Conditions', icon: 'warning', component: SiteConditions },
    { id: 3, title: 'Job Hazards', icon: 'errorcircle', component: JobHazards },
    { id: 4, title: 'HSE Acknowledgement', icon: 'taskcomplete', component: HseAcknowledgement },
    { id: 5, title: 'Analysis', icon: 'datausage', component: JhaAnalysis }
];

export default function Jha() {
    return (
        <div className='jha-home'>
            <ISafeTabPanel tabs={jhaTabs} />
        </div>
    );
}
