import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonGroup, Form, Popup } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/popup';
import { SimpleItem, RequiredRule, Label, ButtonItem, GroupItem } from 'devextreme-react/form';
import { ICyberHazard, ICyberHazardSelectionPoint, SelectionMethod } from '../../../../../interfaces'
import "./add-cyber-hazard.scss";
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { IButtonOptions } from 'devextreme-react/cjs/button';
import { SelectionChangedEvent } from 'devextreme/ui/button_group';

export interface AddCyberHazardDialogProps {
    visible: boolean;
    cyberHazard: ICyberHazard | null;
    onHiding: () => void;
    onApply: (cyberHazard: ICyberHazard) => void;
}

const AddCyberHazardDialog: React.FC<AddCyberHazardDialogProps> = ({
    visible,
    onHiding,
    onApply,
    cyberHazard
}) => {
    const [currentCyberHazard, setCurrentCyberHazard] = useState<ICyberHazard>({
        id: 0,
        name: '',
        createdDate: new Date(),
        isActive: true,
        regionId: Number(localStorage.getItem('region')),
        selectionMethod:SelectionMethod.RadioButton,
        selectionPoints:[]
    });
    const [selectionPoints, setSelectionPoints] = useState<ICyberHazardSelectionPoint[]>([]);
    const formRef = useRef<Form>(null);

    useEffect(() => {
        if (cyberHazard) {
            setSelectionPoints([...cyberHazard.selectionPoints]);
            setCurrentCyberHazard({ ...cyberHazard });
        }
    }, [cyberHazard]);

    const handleApply = () => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            var filterCyberhazard = {
                ...currentCyberHazard,
                name: currentCyberHazard.name?.trim(),
                selectionPoints: selectionPoints.filter(c => c.description?.length > 0)
            }
            onApply(filterCyberhazard);
        }
    };

    const saveButtonOptions = {
        text: 'Save',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleApply,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onHiding,
    };

    // Update selection points
    const handleUpdateSelectionPoint = (e: ValueChangedEvent, index: number) => {
        setSelectionPoints((points) => {
            var point = points[index];
            if (!!point) point.description = e.value?.trim();
            return points;
        });
    };


    const generateNewSelectionPointOptions = useCallback((index:number) => ({
        buttons: [{
            name: 'trash',
            location: 'after',
            options: {
                stylingMode: 'text',
                icon: 'trash',
                onClick: () => {
                    const newlist = selectionPoints.slice(0, index).concat(selectionPoints.slice(index + 1));
                    setSelectionPoints(newlist);
                },
            },
        }],
        value:selectionPoints[index].description,
        placeholder: "Selection Point",
        autoResizeEnabled: true,
    minRowCount: 1,
    maxRowCount: 10,
        onValueChanged:  (e:any) => {handleUpdateSelectionPoint(e,index)}
    }), [selectionPoints]);
    const SelectionPointOptions = useMemo(() => {
        const options: any[] = [];
        for (let i = 0; i < selectionPoints.length; i += 1) {
            options.push(generateNewSelectionPointOptions(i)); 
        }
        return options;
    }, [selectionPoints, generateNewSelectionPointOptions]);

    const addSelectionPointButtonOptions = useMemo(():IButtonOptions => ({
        icon: 'add',
        text: 'Add Another',
        width:'100%',
        stylingMode:'outlined',
        onClick: () => {
            const newMeasure = {
                description: '',
                cyberHazardId: currentCyberHazard.id,
                createdDate: new Date(),
                id: 0
            };
            setSelectionPoints((prevMeasures) => [...prevMeasures, newMeasure]);
        },
    }), [selectionPoints]);
    const selectionMethodGroupItems = [
        { text: "Radio Buttons", key: SelectionMethod.RadioButton },
        { text: "Checkboxes", key: SelectionMethod.Checkbox }
    ];
    const onSelectionMethodChanged = (e: SelectionChangedEvent) => {
        setCurrentCyberHazard((currentValue) => {
            currentValue.selectionMethod=e.addedItems[0].key as SelectionMethod;
            return currentValue;
        } );
    };
    return (
        <Popup
            width={'25%'}
            height={'100%'}
            visible={visible}
            onHiding={onHiding}
            hideOnOutsideClick={false}
            position={'right'}
            title={currentCyberHazard?.id > 0 ? 'Edit CHA' : 'Create CHA'}
        >
            <Form
                formData={currentCyberHazard}
                ref={formRef}
            >
                <SimpleItem dataField="name"
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                    <Label text="CHA Name" />
                    <RequiredRule message="Name is mandatory" />
                </SimpleItem>
                <GroupItem cssClass='selection-points'
                    name="selection-points-container">
                        <Label text="Selection Points"/>
                    <GroupItem
                        name="selection-point">
                        {SelectionPointOptions.map((selectionPoint, index: number) => <SimpleItem
                            key={`selectionPoints.length - selectionPoints[${index}]`}
                            label={{visible:false}}
                            dataField={selectionPoint.value}
                            editorType="dxTextArea"
                            editorOptions={selectionPoint}>
                        </SimpleItem>)}
                    </GroupItem>

                    <ButtonItem
                        horizontalAlignment="left"
                        buttonOptions={addSelectionPointButtonOptions}>
                    </ButtonItem>
                </GroupItem>
                <SimpleItem dataField="selectionMethod">
                    <Label text="Selection Method" />
                    <ButtonGroup
                        items={selectionMethodGroupItems}
                        selectedItemKeys={[currentCyberHazard.selectionMethod]}
                        onSelectionChanged={onSelectionMethodChanged}
                        keyExpr="key"
                        stylingMode="outlined"
                    />
                </SimpleItem>
            </Form>
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={cancelButtonOptions}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={saveButtonOptions}
            />

        </Popup>
    );
};

export default AddCyberHazardDialog;
