import React, { useCallback, useEffect, useState } from 'react';
import './cyber-hazards.scss';
import { DataGrid, Column, Paging, Pager, SearchPanel, DataGridTypes, Selection, MasterDetail } from 'devextreme-react/data-grid';
import { Button, TagBox, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar';
import AddCyberHazardDialog from './dialogs/add-cyber-hazard';
import { ISafeConfirmationButton } from '../../../../components/Customizatons';
import { useLoad, useToast } from '../../../../contexts';
import { ICyberHazard, IActivity, ISiteCondition, IApiResponse } from '../../../../interfaces';
import { cyberHazardsService, activityService, siteConditionService } from '../../../../api';


const CyberHazards: React.FC = () => {
  const { showLoader } = useLoad();
  const { showErrorToast,showSuccessToast } = useToast();
  const [isAddCyberHazardDialogVisible, setIsAddCyberHazardDialogVisible] = useState(false);
  const [currentSelectedCyberHazard, setCurrentSelectedCyberHazard] = useState<ICyberHazard | null>(null);
  const [cyberHazards, setCyberHazards] = useState<ICyberHazard[]>([]);

  const toggleCyberHazardDialog = useCallback(() => {
    setIsAddCyberHazardDialogVisible(prevState => !prevState);
  }, []);
  const fetchCyberHazards = useCallback(async () => {
    showLoader(true);
    try {
      const response = await cyberHazardsService.getAllByRegion();
      if (response.success) {
        setCyberHazards(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Cyber Hazards');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast]);
  
  useEffect(() => {
    fetchCyberHazards();
  }, []);
  const addNewCyberHazard = () => {
    setCurrentSelectedCyberHazard(null);
    setIsAddCyberHazardDialogVisible(!isAddCyberHazardDialogVisible);
  };

  const handleAddEditCyberHazard = useCallback(async (cyberHazard: ICyberHazard) => {
    showLoader(true);
    try {
      let response: IApiResponse<ICyberHazard>;
      if (cyberHazard.id > 0) {
        // Update existing cyberHazard
        response = await cyberHazardsService.update(cyberHazard.id.toString(), cyberHazard);
        if (response.success) {
          setCyberHazards(currentHazards => 
            currentHazards.map(a => 
              a.id === cyberHazard.id ? response.data : a // Update the specific cyberHazard
            )
          );
          showSuccessToast('Cyber Hazard updated successfully!');
          setIsAddCyberHazardDialogVisible(false);
          fetchCyberHazards();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to update the Cyber Hazard: ${response.errors.join('; ')}`);
        }
      } else {
        // Create new CyberHazard
        response = await cyberHazardsService.create(cyberHazard);
        if (response.success) {
          setCyberHazards(currentPpes => [...currentPpes, response.data]); // Add new cyberHazard
          showSuccessToast('Cyber Hazard added successfully!');
          setIsAddCyberHazardDialogVisible(false);
          fetchCyberHazards();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to add the Cyber Hazard: ${response.errors.join('; ')}`);
        }
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast, showSuccessToast]);
  
  const handleDeleteCyberHazard = useCallback((cyberHazardId: number) => {
    showLoader(true);
    cyberHazardsService.delete(cyberHazardId.toString()).then(response => {
      if (response.success) {
        // Remove the cyberHazard from the list
        setCyberHazards(currentPpes => 
          currentPpes.filter(cyberHazard => cyberHazard.id !== cyberHazardId)
        );
        showSuccessToast('Cyber Hazard deleted successfully!');
        fetchCyberHazards();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to delete the Cyber Hazard: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast]);
  const handleActivateCyberHazard = useCallback((cyberHazardId: number,isActive: boolean) => {
    showLoader(true);
    cyberHazardsService.activate(cyberHazardId.toString(),!isActive).then(response => {
      if (response.success) {
        setCyberHazards(currentPpes => 
          {
            var cyberHazardMatch = currentPpes.find(a => a.id==cyberHazardId);
            if(cyberHazardMatch) cyberHazardMatch.isActive =  !cyberHazardMatch?.isActive
            return currentPpes;
          }
        );
        showSuccessToast(`Cyber Hazard ${isActive ?'Disabled':'Enabled'} successfully!`);
        fetchCyberHazards();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to Enable/Disable the Cyber Hazard: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast,cyberHazards]);
  const renderTitle = useCallback(() => {
    return <p className='section-title'><b>CHA - Cyber Hazard Analysis</b></p>;
    }, []);
  const addButtonOptions = {
    icon: 'add',
    text: 'Add New',
    stylingMode: "outlined",
    onClick: addNewCyberHazard
  }


  const onCellClick = useCallback((data: DataGridTypes.CellClickEvent) => {
    setCurrentSelectedCyberHazard(data.data);
  }, []);
  const cellRender = (data: DataGridTypes.ColumnCellTemplateData) => {
    return <div>
      <Button
        text="Edit"
        type="default"
        stylingMode="text"
        onClick={() => {
          setCurrentSelectedCyberHazard(data.data);
          toggleCyberHazardDialog();
        }}
        disabled={!data.data.isActive}
      />
      <ISafeConfirmationButton
        text={data.data.isActive ? 'Disable': 'Enable'}
        type="default"
        stylingMode="text"
        componentName={CyberHazards.name}
        item={data.data}
        onConfirm={() => handleActivateCyberHazard(data.data.id,data.data.isActive)}
      />
      <ISafeConfirmationButton
        text="Delete"
        type="default"
        stylingMode="text"
        componentName={CyberHazards.name}
        item={data.data}
        onConfirm={() => handleDeleteCyberHazard(data.data.id)}
      />
    </div>
  };
  const rowPrepared = useCallback((e: DataGridTypes.RowPreparedEvent) => {
    if (e.rowType === "data") {
      if (!e.data.isActive) {
        e.rowElement.classList.add("inactive-row");
      }
      const plus = document.createElement("i");
      plus.classList.add("dx-icon-add");
      const minus = document.createElement("i");
      minus.classList.add("dx-icon-minus");
      e.rowElement.getElementsByClassName("dx-datagrid-group-closed")[0]?.appendChild(plus)
        e.rowElement.getElementsByClassName("dx-datagrid-group-opened")[0]?.appendChild(minus)
    }
  }, []);
  const selectionPointsRender = useCallback((data: DataGridTypes.ColumnCellTemplateData) => {
    return (
      <div>
        <table className="selection-points-table">
          <tbody>
            {data.data.selectionPoints.map((point:any, index:number) => (
              <tr key={point.id}>
                <td >{index + 1}.</td>
                <td style={{
                    whiteSpace: 'normal',
                    wordBreak: 'break-word'
                  }}>
                  {point.description}
                </td>
                {/* render other point data if necessary */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }, [cyberHazards]);
  
  
  
  return (<>
    <div className='cyber-hazards-container'>
      <Toolbar className='section-toolbar'>
        <Item
          location='before'
          render={renderTitle}
        ></Item>
        <Item
          location='after'
          widget="dxButton"
          options={addButtonOptions}
        ></Item>
      </Toolbar>
      <DataGrid
        dataSource={cyberHazards}
        keyExpr="id"
        showBorders={true}
        allowColumnReordering={true}
        height={'80vh'}
        onCellClick={onCellClick}
        onRowPrepared={rowPrepared}>
        <Selection mode="single" />
        <Paging defaultPageSize={10} />
        <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showNavigationButtons displayMode='compact' showInfo={true} />

        <Column dataField="name" caption="Name" />
        
        <Column dataField="createdDate" caption="Created Date" dataType="date" format={"dd MMM yyyy"} />

        <Column cssClass='actions-column' caption="Actions" cellRender={cellRender}>

        </Column>
        <SearchPanel placeholder={'Search here ...'} width={'auto'} visible={true} searchVisibleColumnsOnly />
        <MasterDetail enabled={true} render={selectionPointsRender} />
      </DataGrid>
    </div>
    {isAddCyberHazardDialogVisible && <AddCyberHazardDialog
      visible={isAddCyberHazardDialogVisible}
      onHiding={toggleCyberHazardDialog}
      onApply={handleAddEditCyberHazard}
      cyberHazard={currentSelectedCyberHazard}
    />
    }
  </>
  );
};

export default CyberHazards;
