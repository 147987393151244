import { useCallback, useEffect, useState } from "react";
import { Button, DataGrid, PieChart, Toolbar, DropDownBox, List } from "devextreme-react";
import { Series, Legend, Label, Format, Export } from 'devextreme-react/chart';
import { useLoad, useToast } from "../../../../contexts";
import { Item } from "devextreme-react/toolbar";
import { ICTCInfo, IChaReportInfo, ICyberHazard, JobType } from "../../../../interfaces";
import { reportsService } from "../../../../api/reports";
import { Column, DataGridTypes, MasterDetail, Pager, Paging } from "devextreme-react/cjs/data-grid";
import { cyberHazardsService } from "../../../../api";
import moment from "moment";
import { erpService } from "../../../../api/common/ErpService";
import ChaAnalysisDetailTemplate from "./components/cha-analysis-detail-count/cha-analysis-detail-count";
import { CAPTIONS, TIME_PERIOD, TOAST_MESSAGES } from "../../../../utils/constants";
import "./cha-analysis.scss";

export default function ChaAnalysis() {
  const { showErrorToast } = useToast();
  const { showLoader } = useLoad();

  const { errorFetchingInspectionData, networkServerError, errorFetchingInspections, errorFetchingCtc } = TOAST_MESSAGES;
  const { completedStatus, incompleteStatus, startDateCaption, endDateCaption, selectDateRange, selectCTC, refresh, overallCountByStatus, overallCountByJobTypes, cyberHazardsAnswered } = CAPTIONS;
  const { last1Day, last7Days, last30Days, last90Days, last12Months} = TIME_PERIOD;

  const [cyberHazards, setCyberHazards] = useState<ICyberHazard[]>([]);
  const [chaReportData, setChaReportData] = useState<IChaReportInfo[]>([]);
  const [overallStatsByStatus, setOverallStatsByStatus] = useState<{ status: string; count: number }[]>([]);
  const [overallStatsByJobType, setOverallStatsByJobType] = useState<{ jobType: string; count: number }[]>([]);

  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000)); // Default to 90 days
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [presetDateRange, setPresetDateRange] = useState<string>(last90Days);
  const [ctcList, setCtcList] = useState<ICTCInfo[]>([]);
  const [selectedCtcs, setSelectedCtcs] = useState<string[]>([]);

  const fetchChaReportData = useCallback(async () => {
    showLoader(true);
    try {
      const response = await reportsService.getChaReportsInfo(
        moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
        moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
        selectedCtcs.join(",")
      );
      if (response.success) {
        const data = response?.data;
        setChaReportData(data);

        // Calculate overall pass/fail counts
        const completed = data?.filter((item) => item.status === completedStatus)?.length;
        const incompleted = data?.filter((item) => item.status === incompleteStatus)?.length;
        setOverallStatsByStatus([
          { status: completedStatus, count: completed },
          { status: incompleteStatus, count: incompleted }
        ]);
        // Calculate overall jobTypes counts
        const project = data?.filter((item) => item.jobType === JobType["Job#"]).length;
        const workOrder = data?.filter((item) => item.jobType === JobType.WorkOrder).length;
        setOverallStatsByJobType([
          { jobType: "Project", count: project },
          { jobType: "WorkOrder", count: workOrder }
        ]);
      } else {
        console.error(response.errors);
        showErrorToast(errorFetchingInspectionData);
      }
    } catch (error) {
      console.error(error);
      showErrorToast(networkServerError);
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast, startDate, endDate, selectedCtcs]);

  const fetchChaAnalysisRecords = useCallback(async () => {
    showLoader(true);
    try {
      const response = await cyberHazardsService.getAllByRegion();
      if (response?.success) {
        setCyberHazards(response.data);
      } else {
        console.error(response.errors);
        showErrorToast(errorFetchingInspections);
      }
    } catch (error) {
      console.error(error);
      showErrorToast(networkServerError);
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast]);
  const fetchCtcs = useCallback(async () => {
    try {
      const response = await erpService.getAllCTCs();
      if (response.success) {
        setCtcList(response.data);
      } else {
        console.error(response.errors);
        showErrorToast(errorFetchingCtc);
      }
    } catch (error) {
      console.error(error);
      showErrorToast(networkServerError);
    }
  }, [showLoader, showErrorToast]);
  const refreshReports = useCallback(() => {
    fetchChaReportData();
  }, [fetchChaReportData]);

  useEffect(() => {
    refreshReports();
  }, [refreshReports]);
  useEffect(() => {
    fetchChaAnalysisRecords();
    fetchCtcs();
  }, [fetchChaAnalysisRecords, fetchCtcs]);

  const handlePresetDateRangeChange = useCallback((e: any) => {
    const value = e.value;
    setPresetDateRange(value);
    const today = new Date();
    switch (value) {
      case last1Day:
        setStartDate(new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case last7Days:
        setStartDate(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case last30Days:
        setStartDate(new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case last90Days:
        setStartDate(new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case last12Months:
        setStartDate(new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()));
        setEndDate(today);
        break;
      default:
        setStartDate(new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000));
        setEndDate(new Date());
        break;
    }
  }, []);

  const [collapsed, setCollapsed] = useState(true);

  const onContentReady = useCallback((e: DataGridTypes.ContentReadyEvent) => {
      if (collapsed) {
        e.component.expandRow(["EnviroCare"]);
        setCollapsed(false);
      }
    }, [collapsed]);

  const rowPrepared = useCallback((e: DataGridTypes.RowPreparedEvent) => {
    if (e.rowType === "data") {
      const plus = document.createElement("i");
      plus.classList.add("dx-icon-add");
      const minus = document.createElement("i");
      minus.classList.add("dx-icon-minus");
      e.rowElement.getElementsByClassName("dx-datagrid-group-closed")[0]?.appendChild(plus);
      e.rowElement.getElementsByClassName("dx-datagrid-group-opened")[0]?.appendChild(minus);
    }
  }, []);

  const onSelectedCtcChange = useCallback((e: any) => {
      if (e.name === "selectedItemKeys") {
        setSelectedCtcs(e.value);
      }
    }, [setSelectedCtcs]);
  return (
    <div className="inspection-analysis-container">
      <Toolbar className="section-toolbar">
        <Item
          location="after"
          locateInMenu="auto"
          widget="dxDateBox"
          options={{
            value: startDate,
            onValueChanged: (e: any) => setStartDate(e.value),
            placeholder: startDateCaption
          }}
        />
        <Item
          location="after"
          locateInMenu="auto"
          widget="dxDateBox"
          options={{
            value: endDate,
            onValueChanged: (e: any) => setEndDate(e.value),
            placeholder: endDateCaption
          }}
        />
        <Item
          location="after"
          locateInMenu="auto"
          widget="dxSelectBox"
          options={{
            items: [
              last1Day,
              last7Days,
              last30Days,
              last90Days,
              last12Months
            ],
            value: presetDateRange,
            onValueChanged: handlePresetDateRangeChange,
            placeholder: selectDateRange
          }}
        />
        <Item
          location="after"
          locateInMenu="auto"
          render={() => (
            <DropDownBox
              dataSource={ctcList}
              valueExpr="name"
              displayExpr="name"
              value={selectedCtcs}
              placeholder={selectCTC}
              width="100%"
            >
              <List
                searchEnabled
                searchExpr="name"
                dataSource={ctcList}
                keyExpr="name"
                displayExpr="name"
                selectedItemKeys={selectedCtcs}
                selectionMode="all"
                showSelectionControls
                selectByClick
                selectAllMode={"allPages"}
                onOptionChanged={onSelectedCtcChange}
              />
            </DropDownBox>
          )}
        />
        <Item
          location="after"
          locateInMenu="auto"
          widget="dxButton"
          showText="inMenu"
        >
          <Button
            text={refresh}
            icon="refresh"
            stylingMode="text"
            onClick={refreshReports}
          />
        </Item>
      </Toolbar>
      <div className="report-section">
        <div className="cha-report-section-piechart">
          <PieChart
            id="overall-complete-incomplete"
            dataSource={overallStatsByStatus}
            title={overallCountByStatus}
          >
            <Series argumentField="status" valueField="count">
              <Label visible={true}>
                <Format type="fixedPoint" precision={0} />
              </Label>
            </Series>
            <Legend visible={true} />
            <Export enabled />
          </PieChart>
        </div>
        <div className="cha-report-section-piechart">
          <PieChart
            id="overall-jobtypes"
            dataSource={overallStatsByJobType}
            title={overallCountByJobTypes}
          >
            <Series argumentField="jobType" valueField="count">
              <Label visible={true}>
                <Format type="fixedPoint" precision={0} />
              </Label>
            </Series>
            <Legend visible={true} />
            <Export enabled />
          </PieChart>
        </div>
      </div>
      <br />
      <div className="datagrid-section">
        <DataGrid
          dataSource={cyberHazards}
          rowAlternationEnabled={true}
          showBorders={true}
          width="100%"
          onContentReady={onContentReady}
          onRowPrepared={rowPrepared}
        >
          <Column dataField="name" caption={cyberHazardsAnswered} />
          <MasterDetail
            enabled={true}
            component={(props) => (
              <ChaAnalysisDetailTemplate
                {...props}
                startDate={startDate}
                endDate={endDate}
                selectedCtcs={selectedCtcs}
              />
            )}
          />
          <Pager
            allowedPageSizes={[10, 25, 50, 100]}
            showPageSizeSelector={true}
          />
          <Paging defaultPageSize={25} />
        </DataGrid>
      </div>
    </div>
  );
}
