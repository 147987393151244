import { Button, TabPanel, Toolbar } from "devextreme-react"
import { IIncidentCreationForm, IInspectionCreationForm, IPreworkInspection, IncidentStatus, IncidentType, InspectionFormData, TabItem } from "../../../../interfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Item } from "devextreme-react/cjs/toolbar";
import { DataGrid, Column, Paging, Pager, SearchPanel, DataGridTypes, Selection } from 'devextreme-react/data-grid';
import { incidentService, inspectionService } from "../../../../api";
import inspections from "../../../inspections/inspections";
import { useLoad, useToast } from "../../../../contexts";
import { ISafeConfirmationButton, ISafeIncidentStatusButton } from "../../../../components/Customizatons";
import ViewInspectionDialog from "./dialogs/view-inspection";

const tabs: TabItem[] = [
    { id: 0, title: 'All' },
    { id: 1, title: 'Pass' },
    { id: 2, title: 'Failed' }
];
export default function InspectionReports() {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [inspectionFormDetails, setInspectionFormDetails] = useState<InspectionFormData | null>(null);
    const [submittedInspections, setSubmittedInspections] = useState<IInspectionCreationForm[]>([]);
    const [currentSelectedInspection, setCurrentSelectedInspection] = useState<IInspectionCreationForm>();

    const [inspections, setInspections] = useState<IPreworkInspection[]>([]);
    const [isViewInspectionDialogVisible, setIsViewInspectionDialogVisible] = useState(false);
    const toggleViewInspectionsDialog = useCallback(() => {
        setIsViewInspectionDialogVisible(prevState => !prevState);
      }, []);
    const { showLoader } = useLoad();
    const { showErrorToast, showSuccessToast } = useToast();
    const fetchInspections = useCallback(async () => {
        showLoader(true);
        try {
            const response = await inspectionService.getAllByRegion();
            if (response.success) {
                const activeItems = response.data?.filter(i => i.isActive) || [];
                setInspections(activeItems);
            } else {
                console.error(response.errors);
            }
        } catch (error) {
            console.error(error);
        } finally {
            showLoader(false);
        }
    }, [showLoader]);

    const fetchSubmittedInspections = useCallback(async () => {
        showLoader(true);
        try {
            const response = await inspectionService.getSubmittedInspections();
            if (response.success) {
                const sortedInspections = response.data.sort((a, b) =>
                    (b.createdDate ? new Date(b.createdDate).getTime() : new Date().getTime()) - (a.createdDate ? new Date(a.createdDate).getTime() : new Date().getTime())
                );
                setSubmittedInspections(sortedInspections);
            } else {
                console.error(response.errors);
            }
        } catch (error) {
            console.error(error);
        } finally {
            showLoader(false);
        }
    }, [showLoader]);

    useEffect(() => {
        fetchInspections();
        fetchSubmittedInspections();
    }, [fetchInspections, fetchSubmittedInspections]);

    const handleSelectihandleFormChanged = (e: any) => {
        setSelectedTabIndex(e.component.option('selectedIndex'));
    };
    const renderTitle = useCallback(() => {
        return <p className='section-title'><b>Inspection Reports</b></p>;
    }, []);
    const cellRender = (data: DataGridTypes.ColumnCellTemplateData) => {
        return <div>
          <Button
            text="View"
            type="default"
            stylingMode="text"
            onClick={() => {
                setCurrentSelectedInspection(data.data)
                toggleViewInspectionsDialog();
            }}
          />
          <ISafeConfirmationButton
            text="Delete"
            type="default"
            componentName={InspectionReports.name}
            stylingMode="text"
            item={data.data}
            onConfirm={() => {
                showSuccessToast('Inspection Deleted successfully!')
                setSubmittedInspections((inspections) => inspections.filter(i => i.id != data.data.id))
            }}
          />
          
        </div>
      };

    const inspectionTypeRenderer = useCallback((data: DataGridTypes.ColumnCellTemplateData) => {
        var inspection = data.data as IInspectionCreationForm;
        return <p>{inspections.find(i => i.id == inspection.inspectionId)?.name}</p>
    }, [inspections, submittedInspections]);


    const statusRenderer = useCallback((data: DataGridTypes.ColumnCellTemplateData) => {
        var inspection = data.data as IInspectionCreationForm;
        let buttonText = '';
        let buttonStyle = {};
        let icon = ''
        if(inspection.isPassed){
            buttonText = 'Pass';
            buttonStyle = { color: '#00B578', borderColor: '#00B578' };
        }
        else{
            buttonText = 'Failed';
            buttonStyle = { color: '#F5222D', borderColor: '#F5222D' };
        }
        

        return (
            <Button
                text={buttonText}
                icon={icon}
                type="default"
                stylingMode="outlined"
                style={buttonStyle}
            />
        );
    }, [inspections,submittedInspections]);
    
    const filteredInspections = useMemo(() => {
        switch (selectedTabIndex) {
            case 0:
                return submittedInspections;
            case 1:
                return submittedInspections.filter(i => i.isPassed);
            case 2:
                return submittedInspections.filter(i => !i.isPassed );
            default:
                return submittedInspections;
        }
    }, [submittedInspections,selectedTabIndex]);
    return <div className="inspecton-reports">
        <Toolbar className='section-toolbar'>
            <Item
                location='before'
                render={renderTitle}
            ></Item>

        </Toolbar>
        <TabPanel
            key={tabs[selectedTabIndex].title}
            className="dx-theme-background-color"
            width={"100%"}
            height={'100%'}
            animationEnabled={true}
            dataSource={tabs}
            tabsPosition={'top'}
            stylingMode={'primary'}
            selectedIndex={selectedTabIndex}
            onSelectionChanged={handleSelectihandleFormChanged}
        />
        <DataGrid
            dataSource={filteredInspections}
            keyExpr="id"
            showBorders={true}
            allowColumnReordering={true}
            height={'75vh'}
        // onRowPrepared={rowPrepared}
        >
            <Selection mode="single" />
            <Paging defaultPageSize={10} />
            <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showNavigationButtons displayMode='compact' showInfo={true} />

            <Column caption="Type"
                cellRender={inspectionTypeRenderer}
            />
            <Column dataField="source" caption="Source" />
            <Column caption="Status"
                cellRender={statusRenderer}
            />
            <Column dataField="createdDate" caption="Inspection Date" dataType="date" format={"dd MMM yyyy"} />

            <Column cssClass='actions-column' caption="Actions" cellRender={cellRender}>

            </Column>
            <SearchPanel placeholder={'Search here ...'} width={'auto'} visible={true} />
        </DataGrid>
        {isViewInspectionDialogVisible && <ViewInspectionDialog
      visible={isViewInspectionDialogVisible}
      onHiding={toggleViewInspectionsDialog}
      submittedInspection={currentSelectedInspection}
      
    />
    }
    </div>
}