import ChaAnalysis from './pages/cha-analysis/cha-analysis';
import ChaAcknowledgement from './pages/cha-acknowledgement/cha-acknowledgement';
import { ISafeTabPanel } from '../../components/Customizatons';
import CyberHazards from './pages/cha-cyber-hazard-analysis/cyber-hazards';
import { TAB_TITLE } from '../../utils/constants';
import './cha.scss';

const { chaCyberHazardAnalysis, chaAcknowledgement, analysis } = TAB_TITLE

const chaTabs = [
    { id: 0, title: chaCyberHazardAnalysis, icon: 'mediumiconslayout', component: CyberHazards },
    { id: 1, title: chaAcknowledgement, icon: 'taskcomplete', component: ChaAcknowledgement },
    { id: 2, title: analysis, icon: 'datausage', component: ChaAnalysis }
];

export default function Cha() {
    return (
        <div className='cha-home'>
            <ISafeTabPanel tabs={chaTabs} />
        </div>
    );
}
