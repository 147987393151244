import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, ButtonGroup, Form, List, Popup, TextBox } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/popup';
import { SimpleItem, RequiredRule, Label, Item as FormItem, ButtonItem, GroupItem } from 'devextreme-react/form';
import { ButtonStyle, SelectionChangedEvent } from 'devextreme/ui/button_group';
import { IControlMeasure, IJobHazard } from '../../../../../interfaces'
import { ISiteCondition } from '../../../../../interfaces'
import { IActivity } from '../../../../../interfaces'
import ArrayStore from 'devextreme/data/array_store';
import "./add-job-hazard.scss";
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { IButtonOptions } from 'devextreme-react/cjs/button';
export interface AddJobHazardDialogProps {
    visible: boolean;
    jobHazard: IJobHazard | null;
    onHiding: () => void;
    onApply: (jobHazard: IJobHazard) => void;
    siteConditions: ISiteCondition[];
    activites: IActivity[];
}

const AddJobHazardDialog: React.FC<AddJobHazardDialogProps> = ({
    visible,
    onHiding,
    onApply,
    jobHazard,
    siteConditions,
    activites
}) => {
    const [currentJobHazard, setCurrentJobHazard] = useState<IJobHazard>({
        id: 0,
        name: '',
        createdDate: new Date(),
        isActive: true,
        regionId: Number(localStorage.getItem('region')),
        activities: [],
        controlMeasures: [],
        siteConditions: []
    });
    const [controlMeasures, setControlMeasures] = useState<IControlMeasure[]>([]);
    const formRef = useRef<Form>(null);

    useEffect(() => {
        if (jobHazard) {
            setControlMeasures([...jobHazard.controlMeasures]);
            setCurrentJobHazard({ ...jobHazard });
        }
    }, [jobHazard]);

    const handleApply = () => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            var filterJobhazard = {
                ...currentJobHazard,
                name: currentJobHazard.name?.trim(),
                controlMeasures: controlMeasures.filter(c => c.name?.length > 0)
            }
            onApply(filterJobhazard);
        }
    };

    const saveButtonOptions = {
        text: 'Save',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleApply,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onHiding,
    };

    // Update control measure
    const handleUpdateControlMeasure = (e: ValueChangedEvent, index: number) => {
        console.log(e);
        setControlMeasures((measures) => {
            var measure = measures[index];
            if (!!measure) measure.name = e.value?.trim();
            return measures;
        });
    };


    const generateNewControlMeasureOptions = useCallback((index:number) => ({
        buttons: [{
            name: 'trash',
            location: 'after',
            options: {
                stylingMode: 'text',
                icon: 'trash',
                onClick: () => {
                    const newlist = controlMeasures.slice(0, index).concat(controlMeasures.slice(index + 1));
                    setControlMeasures(newlist);
                },
            },
        }],
        value:controlMeasures[index].name,
        placeholder: "Control Measure",
        onValueChanged:  (e:any) => {handleUpdateControlMeasure(e,index)}
    }), [controlMeasures]);
    const ControlMeasureOptions = useMemo(() => {
        const options: any[] = [];
        for (let i = 0; i < controlMeasures.length; i += 1) {
            options.push(generateNewControlMeasureOptions(i)); 
        }
        return options;
    }, [controlMeasures, generateNewControlMeasureOptions]);

    const addControlMeasureButtonOptions = useMemo(():IButtonOptions => ({
        icon: 'add',
        text: 'Add Another',
        width:'100%',
        stylingMode:'outlined',
        onClick: () => {
            const newMeasure = {
                name: '',
                jobHazardId: currentJobHazard.id,
                createdDate: new Date(),
                id: 0
            };
            setControlMeasures((prevMeasures) => [...prevMeasures, newMeasure]);
        },
    }), [controlMeasures]);

    return (
        <Popup
            width={'25%'}
            height={'100%'}
            visible={visible}
            onHiding={onHiding}
            hideOnOutsideClick={false}
            position={'right'}
            title={currentJobHazard?.id > 0 ? 'Edit Hazard' : 'Add New Hazard'}
        >
            <Form
                formData={currentJobHazard}
                ref={formRef}
            >
                <SimpleItem dataField="name"
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                    <Label text="Hazard Name" />
                    <RequiredRule message="Name is mandatory" />
                </SimpleItem>
                <GroupItem cssClass='control-measures'
                    name="control-measures-container">
                        <Label text="Control Measures"/>
                    <GroupItem
                        name="control-measure">
                        {ControlMeasureOptions.map((controlMeasure, index: number) => <SimpleItem
                            key={`controlMeasures.length - controlMeasures[${index}]`}
                            label={{visible:false}}
                            dataField={controlMeasure.value}
                            
                            editorOptions={controlMeasure}>
                        </SimpleItem>)}
                    </GroupItem>

                    <ButtonItem
                        horizontalAlignment="left"
                        buttonOptions={addControlMeasureButtonOptions}>
                    </ButtonItem>
                </GroupItem>
                <SimpleItem>

                    <Label>Map to</Label>
                </SimpleItem>
                <SimpleItem
                    dataField="siteConditions"
                    editorType="dxTagBox"
                    editorOptions={{
                        dataSource: new ArrayStore({
                            data: siteConditions,
                            key: "id",
                        }),
                        placeholder: "Select Site Conditions",
                        displayExpr: "name",
                        valueExpr: "id",
                        searchEnabled: true,
                        hideSelectedItems: true,
                        onValueonChanged: (e: any) => setCurrentJobHazard({ ...currentJobHazard, siteConditions: e.value }),
                    }}
                >
                    <Label text="Site Conditions" />
                </SimpleItem>
                <SimpleItem
                    dataField="activities"
                    editorType="dxTagBox"
                    editorOptions={{
                        dataSource: new ArrayStore({
                            data: activites,
                            key: "id",
                        }),
                        placeholder: "Select Activities",
                        displayExpr: "name",
                        valueExpr: "id",
                        searchEnabled: true,
                        hideSelectedItems: true,
                        onValueonChanged: (e: any) => setCurrentJobHazard({ ...currentJobHazard, activities: e.value }),
                    }}
                >
                    <Label text="Activities" />
                </SimpleItem>
            </Form>
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={cancelButtonOptions}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={saveButtonOptions}
            />

        </Popup>
    );
};

export default AddJobHazardDialog;
