import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonGroup, Form, List, NumberBox, Popup, RadioGroup, SelectBox } from 'devextreme-react';
import { TextBox, Button as TextBoxButton, TextBoxTypes } from 'devextreme-react/text-box';

import { ToolbarItem } from 'devextreme-react/popup';
import { SimpleItem, RequiredRule, Label } from 'devextreme-react/form';
import ArrayStore from 'devextreme/data/array_store';
import { ClaimStatus, HazardCategory, IActivity, ICTCInfo, IIncidentCreationForm, IIncidentUpdationForm, IUser, IncidentClassification, IncidentStatus, IncidentType } from '../../../../../interfaces'
import { IJobHazard } from '../../../../../interfaces'
import { IPreworkInspection } from '../../../../../interfaces'
import { erpService } from '../../../../../api/common/ErpService';
import { useToast, useLoad } from '../../../../../contexts';
import { debounce } from 'lodash';
import { incidentService } from '../../../../../api';
import { SelectionChangedEvent } from 'devextreme/ui/button_group';
import { ButtonTypes } from 'devextreme-react/cjs/button';
const yesNoOptions = [{ value: true, text: 'Yes' },
{ value: false, text: 'No' }]
export interface ActivityDialogProps {
    visible: boolean;
    incident: IIncidentCreationForm | null;
    onHiding: () => void;
    onApply: (incident: IIncidentUpdationForm) => void;
}

const EditIncidentDialog: React.FC<ActivityDialogProps> = ({
    visible,
    onHiding,
    onApply,
    incident
}) => {
    const [currentIncidentInfo, setCurrentIncidentInfo] = useState<IIncidentUpdationForm>({
        id: 0,
    });
    const [managerList, setmanagerList] = useState<IUser[]>([]);
    const [hrList, sethrList] = useState<IUser[]>([]);
    const [hrSearchTerm, setHrSearchTerm] = useState('');
    const [managerSearchTerm, setManagerSearchTerm] = useState('');

    const { showErrorToast } = useToast();
    const { showLoader } = useLoad();
    const formRef = useRef<Form>(null);
    const getInidentInfo = async (incident: IIncidentCreationForm) => {
        try {
            showLoader(true)
            const response = await incidentService.getIncidentUpdateInfor(incident.id);
            if (response.data) {
                setCurrentIncidentInfo(response.data);
                if (!!response.data.manager)
                    setManagerSearchTerm(response.data.manager)
                if (!!response.data.hrRepresentative)
                    {
                        setHrSearchTerm(response.data.hrRepresentative);
                    }

            }
            else {
                setCurrentIncidentInfo({
                    id: 0,
                    ctc: incident.ctc,
                    incidentDescription: incident.description,
                    incidentId: incident.id,
                    incidentStatus: incident.incidentStatus,
                })
            }
        } catch (error) {
            console.error('Error fetching Incident Info:', error);
        }
        finally {
            showLoader(false)
        }
    };
    useEffect(() => {
        if (incident) {
            getInidentInfo(incident);
        }
    }, [incident]);

    const handleApply = () => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            onApply(currentIncidentInfo)
        }
    };
    const debounceFetchManagers = useCallback(
        debounce((term) => handleSearch(term, true), 1000),
        []
    );
    const debounceFetchHrs = useCallback(
        debounce((term) => handleSearch(term, false), 1000),
        []
    );
    const handleSearch = useCallback(async (e: string, isManager: boolean) => {
        const setList = isManager ? setmanagerList : sethrList;

        try {
            const value = e;
            showLoader(true);
            if (value.length >= 3) {
                const response = await erpService.getAllUsers(value); // modify this based on the users API
                setList(response.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            showLoader(false);
        }
    }, []);

    const saveButtonOptions = {
        text: 'Save',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleApply,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onHiding,
    };

    const handleManagerChange = useCallback((user: IUser) => {
        setCurrentIncidentInfo({
            ...currentIncidentInfo,
            manager: user?.emailId
        })
        setManagerSearchTerm(user.emailId)
        setmanagerList([]);
    }, [currentIncidentInfo]);
    const handleHrChange = useCallback((user: IUser) => {
        setCurrentIncidentInfo({
            ...currentIncidentInfo,
            hrRepresentative: user?.emailId
        })
        setHrSearchTerm(user.emailId)
        sethrList([]);
    }, [currentIncidentInfo]);
    const clearHrButton = useMemo<ButtonTypes.Properties>(
        () => ({
          icon: 'clear',
          stylingMode: 'text',
          onClick:(e) => {
            setHrSearchTerm('');
            sethrList([]);
          }
        }),
        [],
      );
      const clearManagerButton = useMemo<ButtonTypes.Properties>(
        () => ({
          icon: 'clear',
          stylingMode: 'text',
          onClick:(e) => {
            setManagerSearchTerm('');
            setmanagerList([]);
          }
        }),
        [],
      );
    return (
        <Popup
            width={'25%'}
            height={'100%'}
            visible={visible}
            onHiding={onHiding}
            hideOnOutsideClick={false}
            position={'right'}
            title={'Edit Incident'}
        >
            <Form
                formData={currentIncidentInfo}
                ref={formRef}
            >
                <SimpleItem
                    dataField="incidentStatus"
                    editorType="dxSelectBox"
                    editorOptions={{
                        items: Object.entries(IncidentStatus).map(([key, value]) => ({
                            id: value,
                            name: key.replace(/_/g, ' ')
                        })),
                        placeholder: "Please select Status",
                        displayExpr: "name",
                        valueExpr: "id",
                        searchEnabled: true,
                        hideSelectedItems: true,
                        onValueChanged: (e: any) => setCurrentIncidentInfo({ ...currentIncidentInfo, incidentStatus: e.value }),
                    }}
                >
                    <Label text="Incident Status" />
                </SimpleItem>
                <SimpleItem
                    dataField="ctc"
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                    <Label text="CTC" />
                    <RequiredRule message="CTC is mandatory" />
                </SimpleItem>
                <SimpleItem
                    dataField="manager"

                >
                    <TextBox
                        value={managerSearchTerm}
                        onValueChanged={(e) => {
                            setManagerSearchTerm(e.value);
                        }}
                        onKeyUp={(e: any) => {
                            debounceFetchManagers(e.event.target.value);
                        }}
                        placeholder="Search Manager"
                    >
                        <TextBoxButton
                            name="search"
                            location="after"
                            options={clearManagerButton}
                        />
                    </TextBox>
                    {managerList.length > 0 && (
                        <List
                            style={{maxHeight:'20vh',overflowY:'auto'}}
                            className='list-with-center-allignment'
                            dataSource={managerList}
                            displayExpr={'emailId'}
                            selectByClick={true}
                            onItemClick={({ itemData }) => itemData ? handleManagerChange(itemData) : ''}
                        />
                    )}
                    <Label text="Manager" />
                </SimpleItem>
                <SimpleItem
                    dataField="hrRepresentative"

                >
                    <TextBox
                        value={hrSearchTerm}
                        onValueChanged={(e) => {
                            setHrSearchTerm(e.value);
                        }}
                        onKeyUp={(e: any) => {
                            debounceFetchHrs(e.event.target.value);
                        }}
                        placeholder="Search HR Representative"
                    >
                        <TextBoxButton
                            name="search"
                            location="after"
                            options={clearHrButton}
                        />
                    </TextBox>
                    {hrList.length > 0 && (
                        <List
                            style={{maxHeight:'20vh',overflowY:'auto'}}
                            className='list-with-center-allignment'
                            dataSource={hrList}
                            displayExpr={'emailId'}
                            selectByClick={true}
                            onItemClick={({ itemData }) => itemData ? handleHrChange(itemData) : ''}
                        />
                    )}
                    <Label text="HR Representative" />
                </SimpleItem>
                <SimpleItem
                    dataField="incidentDescription"
                    editorType="dxTextArea"
                    label={{ text: "Incident Description" }}
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                </SimpleItem>
                <SimpleItem
                    dataField="incidentInvestigation"
                    editorType="dxTextArea"
                    label={{ text: "Incident Investigation" }}
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                </SimpleItem>
                <SimpleItem
                    dataField="incidentClassification"
                    editorType="dxSelectBox"
                    editorOptions={{
                        items: Object.entries(IncidentClassification).map(([key, value]) => ({
                            id: value,
                            name: key.replace(/_/g, ' ')
                        })),
                        placeholder: "Please Select",
                        displayExpr: "name",
                        valueExpr: "id",
                        searchEnabled: true,
                        hideSelectedItems: true,
                    }}
                >
                    <Label text="Incident Classification" />
                </SimpleItem>
                <SimpleItem
                    dataField="rootCause"
                    editorType="dxTextArea"
                    label={{ text: "Root Cause" }}
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                </SimpleItem>
                <SimpleItem
                    dataField="correctiveActions"
                    editorType="dxTextArea"
                    label={{ text: "Corrective Actions" }}
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                </SimpleItem>
                <SimpleItem
                    dataField="hazardCategory"
                    editorType="dxSelectBox"
                    editorOptions={{
                        items: Object.entries(HazardCategory).map(([key, value]) => ({
                            id: value,
                            name: key.replace(/_/g, ' ')
                        })),
                        placeholder: "Please select",
                        displayExpr: "name",
                        valueExpr: "id",
                        searchEnabled: true,
                        hideSelectedItems: true,
                    }}
                >
                    <Label text="Hazard Category" />
                </SimpleItem>
                <SimpleItem dataField="netIncurred"
                    editorType='dxNumberBox'
                    editorOptions={{
                        format: '$#,##0.00',
                        placeHolder: '$'
                    }}
                >
                    <Label text="Net Incurred" />

                </SimpleItem>
                <SimpleItem
                    dataField="claimStatus"
                    editorType="dxSelectBox"
                    editorOptions={{
                        items: Object.entries(ClaimStatus).map(([key, value]) => ({
                            id: value,
                            name: key.replace(/_/g, ' ')
                        })),
                        placeholder: "Please select Status",
                        displayExpr: "name",
                        valueExpr: "id",
                        searchEnabled: true,
                        hideSelectedItems: true,
                    }}
                >
                    <Label text="Claim Status" />
                </SimpleItem>
                <SimpleItem dataField="workCompClaimFileDate"
                    editorType='dxDateBox'
                >
                    <Label text="Work Comp Claim Filed Date" />

                </SimpleItem>
                <SimpleItem dataField="enteredInOSHA300Log"
                    editorType='dxRadioGroup'
                    editorOptions={{
                        items: yesNoOptions,
                        valueChangeEvent: 'change',
                        layout: 'horizontal',
                        valueExpr: "value",
                        displayExpr: "text"
                    }}
                >
                    <Label text="Entered in OSHA 300 Log?" />
                </SimpleItem>
                <SimpleItem dataField="incidentReportsOnfile"
                    editorType='dxRadioGroup'
                    editorOptions={{
                        items: yesNoOptions,
                        valueChangeEvent: 'change',
                        layout: 'horizontal',
                        valueExpr: "value",
                        displayExpr: "text"
                    }}>
                    <Label text="Incident Reports on File" />

                </SimpleItem>
                <SimpleItem dataField="safetyAlertOnFile"
                    editorType='dxRadioGroup'
                    editorOptions={{
                        items: yesNoOptions,
                        valueChangeEvent: 'change',
                        layout: 'horizontal',
                        valueExpr: "value",
                        displayExpr: "text"
                    }}>
                    <Label text="Safety Alert on File" />

                </SimpleItem>
                <SimpleItem dataField="restrictedDutyFollowUpMedicalReports"
                    editorType='dxRadioGroup'
                    editorOptions={{
                        items: yesNoOptions,
                        valueChangeEvent: 'change',
                        layout: 'horizontal',
                        valueExpr: "value",
                        displayExpr: "text"
                    }}>
                    <Label text="Restricted Duty Follow-up Medical Reports" />

                </SimpleItem>
                <SimpleItem dataField="oshA300EntryFinalized"
                    editorType='dxRadioGroup'
                    editorOptions={{
                        items: yesNoOptions,
                        valueChangeEvent: 'change',
                        layout: 'horizontal',
                        valueExpr: "value",
                        displayExpr: "text"
                    }}>
                    <Label text="OSHA 300 Entry Finalized" />

                </SimpleItem>
            </Form>
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={cancelButtonOptions}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={saveButtonOptions}
            />
        </Popup>
    );
};

export default EditIncidentDialog;
