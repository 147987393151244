import React, { useCallback, useEffect, useState } from 'react';
import './job-hazards.scss';
import { DataGrid, Column, Paging, Pager, SearchPanel, DataGridTypes, Selection, MasterDetail } from 'devextreme-react/data-grid';
import { Button, TagBox, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar';
import AddJobHazardDialog from './dialogs/add-job-hazard';
import { ISafeConfirmationButton } from '../../../../components/Customizatons';
import { useLoad, useToast } from '../../../../contexts';
import { IJobHazard, IActivity, ISiteCondition, IApiResponse } from '../../../../interfaces';
import { jobHazardsService, activityService, siteConditionService } from '../../../../api';


const JobHazards: React.FC = () => {
  const { showLoader } = useLoad();
  const { showErrorToast,showSuccessToast } = useToast();
  const [isAddJobHazardDialogVisible, setIsAddJobHazardDialogVisible] = useState(false);
  const [currentSelectedJobHazard, setCurrentSelectedJobHazard] = useState<IJobHazard | null>(null);
  const [jobHazards, setJobHazards] = useState<IJobHazard[]>([]);
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [siteConditions, setSiteConditions] = useState<ISiteCondition[]>([]);

  const toggleJobHazardDialog = useCallback(() => {
    setIsAddJobHazardDialogVisible(prevState => !prevState);
  }, []);
  const fetchJobHazards = useCallback(async () => {
    showLoader(true);
    try {
      const response = await jobHazardsService.getAllByRegion();
      if (response.success) {
        setJobHazards(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Job Hazards');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast]);
  const fetchActivities = useCallback(async () => {
    try {
      const response = await activityService.getAllByRegion({isActive:true});
      if (response.success) {
        setActivities(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching activities');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
    }
  }, []);
  const fetchSiteConditions = useCallback(async () => {
    try {
      const response = await siteConditionService.getAllByRegion({isActive:true});
      if (response.success) {
        setSiteConditions(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Site Conditions');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } 
  }, []);
  useEffect(() => {
    fetchJobHazards();
    fetchActivities();
    fetchSiteConditions();
  }, []);
  const addNewJobHazard = () => {
    setCurrentSelectedJobHazard(null);
    setIsAddJobHazardDialogVisible(!isAddJobHazardDialogVisible);
  };

  const handleAddEditJobHazard = useCallback(async (jobHazard: IJobHazard) => {
    showLoader(true);
    try {
      let response: IApiResponse<IJobHazard>;
      if (jobHazard.id > 0) {
        // Update existing jobHazard
        response = await jobHazardsService.update(jobHazard.id.toString(), jobHazard);
        if (response.success) {
          setJobHazards(currentPpes => 
            currentPpes.map(a => 
              a.id === jobHazard.id ? response.data : a // Update the specific jobHazard
            )
          );
          showSuccessToast('Job Hazard updated successfully!');
          setIsAddJobHazardDialogVisible(false);
          fetchJobHazards();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to update the Job Hazard: ${response.errors.join('; ')}`);
        }
      } else {
        // Create new JobHazard
        response = await jobHazardsService.create(jobHazard);
        if (response.success) {
          setJobHazards(currentPpes => [...currentPpes, response.data]); // Add new jobHazard
          showSuccessToast('Job Hazard added successfully!');
          setIsAddJobHazardDialogVisible(false);
          fetchJobHazards();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to add the Job Hazard: ${response.errors.join('; ')}`);
        }
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast, showSuccessToast]);
  
  const handleDeleteJobHazard = useCallback((jobHazardId: number) => {
    showLoader(true);
    jobHazardsService.delete(jobHazardId.toString()).then(response => {
      if (response.success) {
        // Remove the jobHazard from the list
        setJobHazards(currentPpes => 
          currentPpes.filter(jobHazard => jobHazard.id !== jobHazardId)
        );
        showSuccessToast('Job Hazard deleted successfully!');
        fetchJobHazards();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to delete the Job Hazard: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast]);
  const handleActivateJobHazard = useCallback((jobHazardId: number,isActive: boolean) => {
    showLoader(true);
    jobHazardsService.activate(jobHazardId.toString(),!isActive).then(response => {
      if (response.success) {
        setJobHazards(currentPpes => 
          {
            var jobHazardMatch = currentPpes.find(a => a.id==jobHazardId);
            if(jobHazardMatch) jobHazardMatch.isActive =  !jobHazardMatch?.isActive
            return currentPpes;
          }
        );
        showSuccessToast(`Job Hazard ${isActive ?'Disabled':'Enabled'} successfully!`);
        fetchJobHazards();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to Enable/Disable the Job Hazard: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast,jobHazards]);
  const renderTitle = useCallback(() => {
    return <p className='section-title'><b>Job Hazards</b></p>;
    }, []);
  const addButtonOptions = {
    icon: 'add',
    text: 'Add New',
    stylingMode: "outlined",
    onClick: addNewJobHazard
  }


  const onCellClick = useCallback((data: DataGridTypes.CellClickEvent) => {
    setCurrentSelectedJobHazard(data.data);
  }, []);
  const cellRender = (data: DataGridTypes.ColumnCellTemplateData) => {
    return <div>
      <Button
        text="Edit"
        type="default"
        stylingMode="text"
        onClick={() => {
          setCurrentSelectedJobHazard(data.data);
          toggleJobHazardDialog();
        }}
        disabled={!data.data.isActive}
      />
      <ISafeConfirmationButton
        text={data.data.isActive ? 'Disable': 'Enable'}
        type="default"
        stylingMode="text"
        componentName={JobHazards.name}
        item={data.data}
        onConfirm={() => handleActivateJobHazard(data.data.id,data.data.isActive)}
      />
      <ISafeConfirmationButton
        text="Delete"
        type="default"
        stylingMode="text"
        componentName={JobHazards.name}
        item={data.data}
        onConfirm={() => handleDeleteJobHazard(data.data.id)}
      />
    </div>
  };
  const rowPrepared = useCallback((e: DataGridTypes.RowPreparedEvent) => {
    if (e.rowType === "data") {
      if (!e.data.isActive) {
        e.rowElement.classList.add("inactive-row");
      }
      const plus = document.createElement("i");
      plus.classList.add("dx-icon-add");
      const minus = document.createElement("i");
      minus.classList.add("dx-icon-minus");
      e.rowElement.getElementsByClassName("dx-datagrid-group-closed")[0]?.appendChild(plus)
        e.rowElement.getElementsByClassName("dx-datagrid-group-opened")[0]?.appendChild(minus)
    }
  }, []);
  const controlMeasuresRender = useCallback((data: DataGridTypes.ColumnCellTemplateData) => {
    return <TagBox
    dataSource={data.data.controlMeasures}
    inputAttr={{ 'aria-label': 'JobHazard' }}
    displayExpr="name"
    valueExpr="id"
    defaultValue={data.data.controlMeasures}
    disabled
  />
  },[jobHazards]);
  return (<>
    <div className='job-hazards-container'>
      <Toolbar className='section-toolbar'>
        <Item
          location='before'
          render={renderTitle}
        ></Item>
        <Item
          location='after'
          widget="dxButton"
          options={addButtonOptions}
        ></Item>
      </Toolbar>
      <DataGrid
        dataSource={jobHazards}
        keyExpr="id"
        showBorders={true}
        allowColumnReordering={true}
        height={'80vh'}
        onCellClick={onCellClick}
        onRowPrepared={rowPrepared}>
        <Selection mode="single" />
        <Paging defaultPageSize={10} />
        <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showNavigationButtons displayMode='compact' showInfo={true} />

        <Column dataField="name" caption="Name" />
        
        <Column dataField="createdDate" caption="Created Date" dataType="date" format={"dd MMM yyyy"} />

        <Column cssClass='actions-column' caption="Actions" cellRender={cellRender}>

        </Column>
        <SearchPanel placeholder={'Search here ...'} width={'auto'} visible={true} searchVisibleColumnsOnly />
        <MasterDetail enabled={true} render={controlMeasuresRender} />
      </DataGrid>
    </div>
    {isAddJobHazardDialogVisible && <AddJobHazardDialog
      visible={isAddJobHazardDialogVisible}
      onHiding={toggleJobHazardDialog}
      onApply={handleAddEditJobHazard}
      jobHazard={currentSelectedJobHazard}
      activites={activities}
      siteConditions={siteConditions}
    />
    }
  </>
  );
};

export default JobHazards;
