import React from "react";
import Bullet, { Font, Margin, Size, Tooltip } from "devextreme-react/bullet";
import { DataGridTypes } from "devextreme-react/data-grid";
import { CAPTIONS } from "../../../../../../utils/constants";
import './pass-fail-percentage-cell.scss'

const PassFailPercentageCell = (
  cellData: DataGridTypes.ColumnCellTemplateData
) => {
  const { pass, fail } = CAPTIONS;

  const valueYes = cellData.data.percentageOfYes;
  const valueNo = cellData.data.percentageOfNo;

  return (
    <div className="pass-fail-percentage-container">
      <div className="bullet-chart-wrapper">
        <Bullet
          value={valueYes}
          startScaleValue={0}
          endScaleValue={valueYes}
          color="#00B578"
          showTarget={false}
          showZeroLevel={true}
          tooltip={{
            enabled: true,
            format: "fixedPoint",
            customizeTooltip: () => ({ text: `${pass}: ${valueYes}%` }),
          }}
        >
          <Size width={valueYes * 3} height={35} />
          <Margin top={5} bottom={0} left={5} />
          <Tooltip
            enabled={true}
            paddingTopBottom={2}
            zIndex={5}
            customizeTooltip={(data) => ({ text: `${pass}: ${data.value}%` })}
          >
            <Font size={18} />
          </Tooltip>
        </Bullet>
        {valueYes > 0 && (
          <div className="inspection-pass-value">{`${pass}: ${valueYes}%`}</div>
        )}
      </div>
      <div className="bullet-chart-wrapper">
        <Bullet
          value={valueNo}
          startScaleValue={0}
          endScaleValue={valueNo}
          color="#f44336"
          showTarget={false}
          showZeroLevel={true}
          tooltip={{
            enabled: true,
            format: "fixedPoint",
            customizeTooltip: () => ({ text: `${fail}: ${valueNo}%` }),
          }}
        >
          <Size width={valueNo * 3} height={35} />
          <Margin top={5} bottom={0} left={5} />
          <Tooltip
            enabled={true}
            paddingTopBottom={2}
            zIndex={5}
            customizeTooltip={(data) => ({ text: `${fail}: ${data.value}%` })}
          >
            <Font size={18} />
          </Tooltip>
        </Bullet>
        {valueNo > 0 && (
          <div className="inspection-fail-value">{`${fail}: ${valueNo}%`}</div>
        )}
      </div>
    </div>
  );
};

export default PassFailPercentageCell;
