import { useCallback, useEffect, useState } from "react";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import { licenseKey } from "./devextreme-license";
import config from "devextreme/core/config";
import ErrorBoundary from "./utils/error-boundary";
import {
  NavigationProvider,
  LoadProvider,
  ToastProvider,
  RegionProvider,
} from "./contexts";
import Auth0ProviderConfig from "./Auth0ProviderConfig";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadPanel } from "devextreme-react";
import { updateTokenProvider } from "./api";
import { CONFIG_URL, ROLES } from "./utils/constants";
import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import "./dx-styles.scss";

config({
  licenseKey
});

function App() {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    getIdTokenClaims,
  } = useAuth0();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const getApiBaseUrl = useCallback(async () => {
    try {
      const response = await fetch(CONFIG_URL);
      const { api } = await response.json();
      window.localStorage.setItem("iSafe2BaseUrl", api?.iSafe2BaseUrl);
    } catch (error) {
      return error;
    }
  }, []);

  const getUserRoles = async () => {
    try {
      const response = await fetch(CONFIG_URL);
      const { roles } = await response.json();
      return roles;
    } catch (error) {
      return error;
    }
  };

  const getAuthorisationStatus = useCallback(async () => {
    const userRoles = await getUserRoles();
    const idToken = await getIdTokenClaims();
    const authUsersRoles = idToken && idToken[ROLES];
    console.log(authUsersRoles)
    const hasUserAccess = authUsersRoles?.includes(userRoles?.iSafeAdmin);
    console.log(hasUserAccess)
    setIsAuthorized(hasUserAccess);
  }, [getIdTokenClaims]);

  useEffect(() => {
    isAuthenticated && getAuthorisationStatus();
  }, [isAuthenticated, getAuthorisationStatus]);

  useEffect(() => {
    const storedAuthorization = localStorage.getItem("isAuthorized");
    if (storedAuthorization === "true") {
      setIsAuthorized(true);
    }
  }, [setIsAuthorized]);

  useEffect(() => {
    getApiBaseUrl();
    updateTokenProvider(() => getAccessTokenSilently().catch((error) => {
      console.error("Failed to get access token silently:", error);
      return null;
    }));
  }, [isAuthenticated, getApiBaseUrl, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && isAuthorized && location.pathname !== "/") {
      const existingUrl = localStorage.getItem("lastVisitedUrl");
      if (!existingUrl || existingUrl === "/") {
        localStorage.setItem("lastVisitedUrl", location.pathname);
      }
    }
  }, [isAuthenticated, isAuthorized, location.pathname]);

  useEffect(() => {
    const lastVisitedUrl = localStorage.getItem("lastVisitedUrl");
    if (isAuthorized && lastVisitedUrl) {
      navigate(lastVisitedUrl);
      localStorage.removeItem("lastVisitedUrl");
    } else if (isAuthorized) {
      navigate("/jha");
    }
  }, [isAuthorized, navigate]);

  if (isLoading) {
    return (
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={"center"}
        visible={true}
        showIndicator={true}
      />
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  return <Content isAuthorized={isAuthorized} />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Auth0ProviderConfig>
      <BrowserRouter>
        <NavigationProvider>
          <ErrorBoundary>
            <LoadProvider>
              <ToastProvider>
                <RegionProvider>
                  <div className={`app ${screenSizeClass}`}>
                    <App />
                  </div>
                </RegionProvider>
              </ToastProvider>
            </LoadProvider>
          </ErrorBoundary>
        </NavigationProvider>
      </BrowserRouter>
    </Auth0ProviderConfig>
  );
}
