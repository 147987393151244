import { IApiResponse, IPreworkInspection, IInspectionCreationForm, InspectionFormData, IJobCountByCTC, IInspectionDetails, IInspectionAnswerPercentage, IChaReportInfo, IChaAnswerPercentage, IJhaReportInfo } from '../../interfaces';
import { IIncidentReportInfo } from '../../interfaces/reports/IIncidentReportInfo';
import { ApiService } from '../ApiService';
import axiosInstance from '../AxiosService';
import { AxiosResponse } from 'axios';


const endpoint=  'Report'
class ReportsService extends ApiService<IPreworkInspection> {
  constructor() {
    super(endpoint);
  }
  getJobCountByCTC(fromDate:string,toDate:string): Promise<IApiResponse<IJobCountByCTC[]>> {
    return axiosInstance.get(`${endpoint}/CTC/JobCount?regionId=${localStorage.getItem('region')}&fromDate=${fromDate}&toDate=${toDate}`)
      .then((response: AxiosResponse) => this.handleJobCountByCTCArrayResponse(response));
  }
  getSubmittedInspections(fromDate:string,toDate:string,ctc?:string): Promise<IApiResponse<IInspectionDetails[]>> {
    return axiosInstance.get(`${endpoint}/Inspection/GetInspectionWithStatus?regionId=${localStorage.getItem('region')}&fromDate=${fromDate}&toDate=${toDate}&ctc=${ctc}`)
      .then((response: AxiosResponse) => this.handleIInspectionDetailsArrayResponse(response));
  }
  getInspectionAnswersPercentage(inspectionId:number,fromDate:string,toDate:string,ctc?:string): Promise<IApiResponse<IInspectionAnswerPercentage[]>> {
    return axiosInstance.get(`${endpoint}/Inspection/GetQuestionsAvg/${inspectionId}?fromDate=${fromDate}&toDate=${toDate}&ctc=${ctc}`)
      .then((response: AxiosResponse) => this.handleInspectionAnswersPercentageArrayResponse(response));
  }
  getChaReportsInfo(fromDate:string,toDate:string,ctc?:string): Promise<IApiResponse<IChaReportInfo[]>> {
    return axiosInstance.get(`${endpoint}/Cha/GetCHAReportInfo?regionId=${localStorage.getItem('region')}&fromDate=${fromDate}&toDate=${toDate}&ctc=${ctc}`)
      .then((response: AxiosResponse) => this.handleChaReportsInfoArrayResponse(response));
  }
  getChaAnswersPercentage(chaAnalysisId:number,fromDate:string,toDate:string,ctc?:string): Promise<IApiResponse<IChaAnswerPercentage[]>> {
    return axiosInstance.get(`${endpoint}/cha/GetCHAQuestionsAvg/${chaAnalysisId}?fromDate=${fromDate}&toDate=${toDate}&ctc=${ctc}`)
      .then((response: AxiosResponse) => this.handleChaAnswersPercentageArrayResponse(response));
  }
  getIncidentsReportsInfo(fromDate:string,toDate:string,ctc?:string): Promise<IApiResponse<IIncidentReportInfo[]>> {
    return axiosInstance.get(`${endpoint}/Incident/GetIncidentReportInfo?regionId=${localStorage.getItem('region')}&fromDate=${fromDate}&toDate=${toDate}&ctc=${ctc}`)
      .then((response: AxiosResponse) => this.handleIncidentsReportsInfoArrayResponse(response));
  }
  private handleIncidentsReportsInfoArrayResponse(response: AxiosResponse<IApiResponse<IIncidentReportInfo[]>>): IApiResponse<IIncidentReportInfo[]> {
    return response.data;
  }
  getJhaReportsInfo(fromDate:string,toDate:string): Promise<IApiResponse<IJhaReportInfo[]>> {
    return axiosInstance.get(`${endpoint}/Jha/GetJHAReportInfo?regionId=${localStorage.getItem('region')}&fromDate=${fromDate}&toDate=${toDate}`)
      .then((response: AxiosResponse) => this.handleJhaReportsInfoArrayResponse(response));
  }
  private handleChaAnswersPercentageArrayResponse(response: AxiosResponse<IApiResponse<IChaAnswerPercentage[]>>): IApiResponse<IChaAnswerPercentage[]> {
    return response.data;
  }
  private handleChaReportsInfoArrayResponse(response: AxiosResponse<IApiResponse<IChaReportInfo[]>>): IApiResponse<IChaReportInfo[]> {
    return response.data;
  }
  private handleJhaReportsInfoArrayResponse(response: AxiosResponse<IApiResponse<IJhaReportInfo[]>>): IApiResponse<IJhaReportInfo[]> {
    return response.data;
  }
  private handleInspectionAnswersPercentageArrayResponse(response: AxiosResponse<IApiResponse<IInspectionAnswerPercentage[]>>): IApiResponse<IInspectionAnswerPercentage[]> {
    return response.data;
  }
  private handleIInspectionDetailsArrayResponse(response: AxiosResponse<IApiResponse<IInspectionDetails[]>>): IApiResponse<IInspectionDetails[]> {
    return response.data;
  }
  private handleJobCountByCTCArrayResponse(response: AxiosResponse<IApiResponse<IJobCountByCTC[]>>): IApiResponse<IJobCountByCTC[]> {
    return response.data;
  }
  // Additional methods specific to the User model can be added here
}

export const reportsService = new ReportsService();