// Incidents.tsx
import React from 'react';
import './incidents.scss';
import IncidentsManagement from './pages/incidents-management/incidents-management';
import LearningFromIncidents from './pages/learning-from-incidents/learning-from-incidents';
import { ISafeTabPanel } from '../../components/Customizatons';
import IncidentsAnalysis from './pages/incidents-analysis/incidents-analysis';

const incidentsTabs = [
  { id: 0, title: 'Incidents Management', icon: 'mediumiconslayout', component: IncidentsManagement },
  { id: 1, title: 'Incidents Analysis', icon: 'taskcomplete', component: IncidentsAnalysis }
  // { id: 2, title: 'Learning from Incidents', icon: 'datausage', component: LearningFromIncidents }
];

export default function Incidents() {
  return (
    <div className='incidents-home'>
      <ISafeTabPanel tabs={incidentsTabs} />
    </div>
  );
}
