import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, Column, DataGridTypes } from "devextreme-react/data-grid";
import { reportsService } from "../../../../../../api/reports";
import { ICyberHazard } from "../../../../../../interfaces";
import { useToast } from "../../../../../../contexts";
import { LoadIndicator } from "devextreme-react";
import PassFailPercentageCell from "../pass-fail-percentage-cell/pass-fail-percentage-cell";
import moment from "moment";
import { TOAST_MESSAGES, CAPTIONS } from "../../../../../../utils/constants";
import "./cha-analysis-detail-count.scss";

interface IInspectionAnswerPercentageAsNumbers {
  question: string;
  percentageOfYes: number;
  percentageOfNo: number;
}

interface DetailTemplateProps {
  data: DataGridTypes.MasterDetailTemplateData;
  startDate: Date | null;
  endDate: Date | null;
  selectedCtcs: string[];
}

const ChaAnalysisDetailTemplate: React.FC<DetailTemplateProps> = ({
  data,
  startDate,
  endDate,
  selectedCtcs,
}) => {
  const cha = data.data as ICyberHazard;
  const { showErrorToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [answersByCha, setAnswersByCha] = useState<
    IInspectionAnswerPercentageAsNumbers[]
  >([]);
  const { errorFetchingInspectionDetails, networkServerError } = TOAST_MESSAGES;
  const { selectedNotSelected } = CAPTIONS
  
  const fetchChaAnswersPercentage = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await reportsService.getChaAnswersPercentage(
        cha.id,
        moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
        moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
        selectedCtcs.join(",")
      );
      if (response?.success) {
        setAnswersByCha(
          response?.data?.map((answer) => ({
            question: answer.question,
            percentageOfYes: parseFloat(
              answer.percentageOfYes.replace("%", "")
            ),
            percentageOfNo: parseFloat(answer.percentageOfNo.replace("%", "")),
          }))
        );
      } else {
        console.error(response.errors);
        showErrorToast(errorFetchingInspectionDetails);
      }
    } catch (error) {
      console.error(error);
      showErrorToast(networkServerError);
    } finally {
      setIsLoading(false);
    }
  }, [showErrorToast, cha?.id, startDate, endDate, selectedCtcs]);

  useEffect(() => {
    fetchChaAnswersPercentage();
  }, [fetchChaAnswersPercentage]);

  return (
    <>
      {isLoading ? (
        <div className="cha-analysis-loading-indicator">
          <LoadIndicator />
        </div>
      ) : (
        <DataGrid
          dataSource={answersByCha}
          showBorders={true}
          columnAutoWidth={true}
          className="cha-analysis-child-grid"
          disabled={isLoading}
        >
          <Column width={"80%"} dataField="question" />
          <Column
            dataField="percentageOfYes"
            caption={selectedNotSelected}
            dataType="number"
            alignment="left"
            allowGrouping={false}
            cellRender={PassFailPercentageCell}
            cssClass="bullet"
          />
        </DataGrid>
      )}
    </>
  );
};

export default ChaAnalysisDetailTemplate;