import RegionManagement from './pages/region-management/region-management';
import { ISafeTabPanel } from '../../components/Customizatons';
import './admin.scss';

const incidentsTabs = [
  { id: 0, title: 'Countries', icon: 'globe', component: RegionManagement }
];

export default function Admin() {
  return (
    <div className='admin-home'>
      <ISafeTabPanel tabs={incidentsTabs} />
    </div>
  );
}
