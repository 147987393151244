import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Popup, TextBox } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/popup';
import { SimpleItem, RequiredRule, Label } from 'devextreme-react/form';
import ArrayStore from 'devextreme/data/array_store';
import { IActivity, IInspectionCreationForm, InspectionFormData } from '../../../../../interfaces'
import { IJobHazard } from '../../../../../interfaces'
import { IPreworkInspection } from '../../../../../interfaces'
import DynamicForm from '../components/inspection-detail-form';
import { inspectionService } from '../../../../../api';
import { useLoad } from '../../../../../contexts';

export interface ViewInspectionProps {
    visible: boolean;
    submittedInspection?:IInspectionCreationForm
    onHiding: () => void;
}

const ViewInspectionDialog: React.FC<ViewInspectionProps> = ({
    visible,
    onHiding,
    submittedInspection
    
}) => {
    const [inspectionFormDetails, setInspectionFormDetails] = useState<InspectionFormData | null>(null);

    const { showLoader } = useLoad();

    const formRef = useRef<Form>(null);
    useEffect(() => {
        submittedInspection?.inspectionId && fetchInspectionFormDetails(submittedInspection?.inspectionId);
    },[])

    const fetchInspectionFormDetails = useCallback(async (inspectionId:number) => {
        showLoader(true);
        try {
          const response = await inspectionService.getFormDetails(inspectionId);
          if (response.success) {
            response.data.id = inspectionId;
            setInspectionFormDetails(response.data);
          } else {
            console.error(response.errors);
          }
        } catch (error) {
          console.error(error);
        } finally {
          showLoader(false);
        }
      }, []);
    const cancelButtonOptions = {
        text: 'Close',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onHiding,
    };
    return (
        <Popup
            width={'25%'}
            height={'100%'}
            visible={visible}
            onHiding={onHiding}
            hideOnOutsideClick={false}
            position={'right'}
            title={'View Inspection'}
        >
            {inspectionFormDetails && <DynamicForm formData={inspectionFormDetails} initialValues={submittedInspection}/>}
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={cancelButtonOptions}
            />

        </Popup>
    );
};

export default ViewInspectionDialog;
