import React, { useCallback, useState } from 'react';
import { Button, IButtonOptions } from 'devextreme-react/button';
import { IPopoverOptions, Popover, ToolbarItem } from 'devextreme-react/popover';
import { IBase, IIncidentCreationForm, IncidentStatus } from '../../../interfaces'
import './IncidentStatusButton.scss';
import { RadioGroup } from 'devextreme-react';
import { RadioGroupTypes } from 'devextreme-react/cjs/radio-group';
interface IncidentStatusChangeButtonProps extends IButtonOptions {
    item: IIncidentCreationForm;
    componentName:string;
    onStatusChange: (status:IncidentStatus) => void;
}
export const statusItems = [
    { id: IncidentStatus.REPORTED, text: 'New' },
    { id: IncidentStatus.UNDER_INVESTIGATION, text: 'Under Investigation' },
    { id: IncidentStatus.CLOSED, text: 'Closed' },
  ];
const animationConfig: IPopoverOptions['animation'] = {
  show: {
    type: 'pop',
    from: {
      scale: 0,
    },
    to: {
      scale: 1,
    },
  },
  hide: {
    type: 'fade',
    from: 1,
    to: 0,
  },
};
const IncidentStatusChangeButton: React.FC<IncidentStatusChangeButtonProps> = ({
    item,
    onStatusChange,
    componentName,
    ...props
}) => {
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const togglePopover = () => setIsPopoverVisible(!isPopoverVisible);
    const [selectionPriority, setSelectionPriority] = useState(item.incidentStatus);
  
    const changeSelectionPriority = useCallback((e: RadioGroupTypes.ValueChangedEvent) => {
        onStatusChange(e.value);
    }, [setSelectionPriority]);
    return (
        <>
            <Button
                id={`${componentName}-${item.id}`}
                onClick={togglePopover}
                {...props}
            />
            <Popover
                target={`#${componentName}-${item.id}`}
                visible={isPopoverVisible}
                showEvent="click"
                onHiding={togglePopover}
                position="bottom"
                width={250}
                animation={animationConfig}
                className='incident-status-popover'
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <RadioGroup
              id="radio-group-with-selection"
              items={statusItems}
              value={selectionPriority}
              valueExpr="id"
              displayExpr="text"
              onValueChanged={changeSelectionPriority}
            />
                    </div>

                
            </Popover>
        </>
    );
};

export default IncidentStatusChangeButton;
