export const CONFIG_URL = "/config.json";

export const COMMON = {
  accessDeniedTitle: "Access Denied!",
  accessDeniedDescription: "We are sorry, you do not have the required permissions to access this page.",
};

export const TAB_TITLE = {
  chaCyberHazardAnalysis: "CHA - Cyber Hazard Analysis",
  chaAcknowledgement: "CHA Acknowledgement",
  analysis: "Analysis",
  inspectionsManagement: "Inspections Management",
  inspectionReports: "Inspection Reports",
};

export const CAPTIONS = {
  selectedNotSelected: "Selected/NotSelected %",
  selected: "Selected",
  notSelected: "Not Selected",
  completedStatus: "Completed",
  incompleteStatus: "InComplete",
  startDateCaption: "Start Date",
  endDateCaption: "End Date",
  selectDateRange: "Select Date Range",
  selectCTC: "Select CTCs",
  refresh: "Refresh",
  overallCountByStatus: "Overall Counts by Status",
  overallCountByJobTypes: "Overall Counts by Job Types",
  cyberHazardsAnswered: "Cyber Hazards Answered %",
  passFail: "Pass/Fail %",
  pass: "Pass",
  fail: "Fail",
  passedStatus: "Passed",
  failedStatus: "Failed",
  passFailPercentageofQuestions: "Pass/Fail % of Questions per Inspection",
};

export const TOAST_MESSAGES = {
  errorFetchingInspectionDetails: "Error fetching Inspection Details",
  errorFetchingInspectionData: "Error fetching Inspection Data",
  errorFetchingInspections: "Error fetching Inspections",
  networkServerError: "Network or server error",
  errorFetchingCtc: "Error fetching CTCs",
};

export const TIME_PERIOD = {
  last1Day: "Last 1 day",
  last7Days: "Last 7 days",
  last30Days: "Last 30 days",
  last90Days: "Last 90 days",
  last12Months: "Last 12 months",
};

export const ROLES = 'convergint-aad/roles';