import { COMMON } from "../../utils/constants";
import "./notauthorized.scss";

const NotAuthorized = () => {
  const { accessDeniedTitle, accessDeniedDescription } = COMMON;
  return (
    <div className="not-authorised">
      <i className="dx-icon-lock" />
      <h2 className="sub-title">{accessDeniedTitle}</h2>
      <p>{accessDeniedDescription}</p>
    </div>
  );
};

export default NotAuthorized;
