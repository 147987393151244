import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ppe.scss';
import { DataGrid, Column, Paging, Pager, SearchPanel, DataGridTypes, Selection } from 'devextreme-react/data-grid';
import { Button, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar'
import { ppeService } from '../../../../api';
import AddPpeDialog from './dialogs/add-ppe';
import { ISafeConfirmationButton } from '../../../../components/Customizatons';
import { useLoad, useToast } from '../../../../contexts';
import { IPpe, IApiResponse } from '../../../../interfaces';


const Ppe: React.FC = () => {
  const { showLoader } = useLoad();
  const { showErrorToast, showSuccessToast } = useToast();
  const [isAddPpeDialogVisible, setisAddPpeDialogVisible] = useState(false);
  const [currentSelectedPpe, setcurrentSelectedPpe] = useState<IPpe | null>(null);
  const [ppes, setPpes] = useState<IPpe[]>([]);
  const togglePpeDialog = useCallback(() => {
    setisAddPpeDialogVisible(prevState => !prevState);
  }, []);
  const fetchPpes = useCallback(async () => {
    showLoader(true);
    try {
      const response = await ppeService.getAllByRegion();
      if (response.success) {
        setPpes(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching PPEs');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, []);

  useEffect(() => {
    fetchPpes();
  }, []);
  const addNewPpe = () => {
    setcurrentSelectedPpe(null);
    setisAddPpeDialogVisible(!isAddPpeDialogVisible);
  };

  const handleAddEditPpe = useCallback(async (ppe: IPpe) => {
    showLoader(true);
    try {
      let response: IApiResponse<IPpe>;
      if (ppe.id > 0) {
        // Update existing ppe
        response = await ppeService.update(ppe.id.toString(), ppe);
        if (response.success) {
          setPpes(currentPpes =>
            currentPpes.map(a =>
              a.id === ppe.id ? response.data : a // Update the specific ppe
            )
          );
          showSuccessToast('PPE updated successfully!');
          setisAddPpeDialogVisible(false);
          fetchPpes();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to update the PPE: ${response.errors.join('; ')}`);
        }
      } else {
        // Create new PPE
        response = await ppeService.create(ppe);
        if (response.success) {
          setPpes(currentPpes => [...currentPpes, response.data]); // Add new ppe
          showSuccessToast('PPE added successfully!');
          setisAddPpeDialogVisible(false);
          fetchPpes();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to add the PPE: ${response.errors.join('; ')}`);
        }
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast, showSuccessToast]);

  const handleDeletePpe = useCallback((ppeId: number) => {
    showLoader(true);
    ppeService.delete(ppeId.toString()).then(response => {
      if (response.success) {
        // Remove the ppe from the list
        setPpes(currentPpes =>
          currentPpes.filter(ppe => ppe.id !== ppeId)
        );
        showSuccessToast('PPE deleted successfully!');
        fetchPpes();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to delete the PPE: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast]);
  const handleActivatePpe = useCallback((ppeId: number, isActive: boolean) => {
    showLoader(true);
    ppeService.activate(ppeId.toString(), !isActive).then(response => {
      if (response.success) {
        setPpes(currentPpes => {
          var ppeMatch = currentPpes.find(a => a.id == ppeId);
          if (ppeMatch) ppeMatch.isActive = !ppeMatch?.isActive
          return currentPpes;
        }
        );
        showSuccessToast(`PPE ${isActive ? 'Disabled' : 'Enabled'} successfully!`);
        fetchPpes();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to Enable/Disable the PPE: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast, ppes]);
  const renderTitle = useCallback(() => {
    return <p className='section-title'><b>PPE - Personal Protective Equipment</b></p>;
  }, []);
  const addButtonOptions = {
    icon: 'add',
    text: 'Add New',
    stylingMode: "outlined",
    onClick: addNewPpe
  }


  const onCellClick = useCallback((data: DataGridTypes.CellClickEvent) => {
    setcurrentSelectedPpe(data.data);
  }, []);
  const cellRender = (data: DataGridTypes.ColumnCellTemplateData) => {
    return <div>
      <Button
        text="Edit"
        type="default"
        stylingMode="text"
        onClick={() => {
          setcurrentSelectedPpe(data.data);
          togglePpeDialog();
        }}
        disabled={!data.data.isActive}
      />
      <ISafeConfirmationButton
        text={data.data.isActive ? 'Disable' : 'Enable'}
        type="default"
        componentName={Ppe.name}
        stylingMode="text"
        item={data.data}
        onConfirm={() => handleActivatePpe(data.data.id, data.data.isActive)}
      />
      <ISafeConfirmationButton
        text="Delete"
        type="default"
        componentName={Ppe.name}
        stylingMode="text"
        item={data.data}
        onConfirm={() => handleDeletePpe(data.data.id)}
      />
    </div>
  };
  const cellRenderMandatoryIcon = (data: DataGridTypes.ColumnCellTemplateData) => {
    const iconClass = data.data.isMandatory ? 'is-mandatory-ppe' : 'is-not-mandatory-ppe';
    if (data.data.isMandatory)
      return (
        <i className={`dx-icon-errorcircle ${iconClass}`}></i>
      );
  };
  const rowPrepared = useCallback((e: DataGridTypes.RowPreparedEvent) => {
    if (e.rowType === "data") {
      if (!e.data.isActive) {
        e.rowElement.classList.add("inactive-row");
      }
    }
  }, []);
  return (<>
    <div className='ppe-container'>
      <Toolbar className='section-toolbar'>
        <Item
          location='before'
          render={renderTitle}
        ></Item>
        <Item
          location='after'
          widget="dxButton"
          options={addButtonOptions}
        ></Item>
      </Toolbar>
      <DataGrid
        dataSource={ppes}
        keyExpr="id"
        showBorders={true}
        allowColumnReordering={true}
        height={'80vh'}
        onCellClick={onCellClick}
        onRowPrepared={rowPrepared}>
        <Selection mode="single" />
        <Paging defaultPageSize={10} />
        <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showNavigationButtons displayMode='compact' showInfo={true} />
        <Column cellRender={cellRenderMandatoryIcon} width='auto' alignment="center" />

        <Column dataField="name" caption="Name" />

        <Column dataField="createdDate" caption="Created Date" dataType="date" format={"dd MMM yyyy"} />

        <Column cssClass='actions-column' caption="Actions" cellRender={cellRender}>

        </Column>
        <SearchPanel placeholder={'Search here ...'} width={'auto'} visible={true} searchVisibleColumnsOnly />
      </DataGrid>
    </div>
    {isAddPpeDialogVisible && <AddPpeDialog
      visible={isAddPpeDialogVisible}
      onHiding={togglePpeDialog}
      onApply={handleAddEditPpe}
      ppe={currentSelectedPpe}
    />
    }
  </>
  );
};

export default Ppe;
