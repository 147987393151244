import { Routes, Route, Navigate } from "react-router-dom";
import appInfo from "./app-info";
import routes from "./app-routes";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
interface AuthorizationProps {
  isAuthorized: boolean;
}

export default function Content({ isAuthorized }: Readonly<AuthorizationProps>) {
  return (
    <SideNavBarLayout title={appInfo.title}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<element.type isAuthorized={isAuthorized} {...element.props} />}
          />
        ))}
        <Route path="*" element={<Navigate to="/jha" />} />
      </Routes>
    </SideNavBarLayout>
  );
}