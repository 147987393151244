import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { IToastOptions, Position, Toast } from 'devextreme-react/toast';

interface ToastInfo {
  message: string;
  type: 'info' | 'success' | 'error' | 'warning';
  visible: boolean;
}
const animationConfig: IToastOptions['animation'] = {
  show: {
    type: 'fade',
    duration:400,
    from: 0,
    to: 1,
  },
  hide: {
    type: 'fade',
    from: 1,
    to: 0,
    duration:40,
  },
};
interface ToastContextType {
  showSuccessToast: (message: string) => void;
  showErrorToast: (message: string) => void;
  showWarningToast: (message: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toastInfo, setToastInfo] = useState<ToastInfo>({ message: '', type: 'info', visible: false });
  const showSuccessToast = useCallback((message: string) => {
    setToastInfo({ message, type: 'success', visible: true });
  }, []);
  const showErrorToast = useCallback((message: string) => {
    setToastInfo({ message, type: 'error', visible: true });
  }, []);
  const showWarningToast = useCallback((message: string) => {
    setToastInfo({ message, type: 'warning', visible: true });
  }, []);
  const contentRender = () => {
    return (
        <div className="toaster-flex-box">
            <span>{toastInfo.message} &nbsp;</span>
            <i onClick={() => setToastInfo(info => ({ ...info, visible: false }))} className='dx-icon-close toaster-close-icon-style'></i>
        </div>
    );
}
  return (
    <ToastContext.Provider value={{ showSuccessToast, showErrorToast, showWarningToast }}>
      {children}
      <Toast
        key={new Date().getTime()}
        visible={toastInfo.visible}
        contentRender={contentRender}
        type={toastInfo.type}
        onHiding={(e:any)=> toastInfo.type =='error' ? e.cancel=toastInfo.visible: setToastInfo(info => ({ ...info, visible: false }))}
        displayTime={toastInfo.type =='error'? 0:3000}
        animation= {animationConfig}
        focusStateEnabled
      >
        <Position
          my="top"
          at="bottom"
          offset={'0 20'}
          of='#i-safe-header'
        />
      </Toast>
    </ToastContext.Provider>
  );
};
