import React, { useEffect, useRef, useState } from 'react';
import { ButtonGroup, Form, Popup, TextBox } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/popup';
import { SimpleItem, RequiredRule, Label } from 'devextreme-react/form';
import { IPpe } from '../../../../../interfaces'
import { SelectionChangedEvent } from 'devextreme/ui/button_group';

export interface AddPpeDialogProps {
    visible: boolean;
    ppe: IPpe | null;
    onHiding: () => void;
    onApply: (ppe: IPpe) => void;
}

const AddPpeDialog: React.FC<AddPpeDialogProps> = ({
    visible,
    onHiding,
    onApply,
    ppe
}) => {
    const [currentPpe, setCurrentPpe] = useState<IPpe>({
        id: 0,
        name: '',
        createdDate: new Date(),
        isActive: true,
        isMandatory: false,
        regionId: Number(localStorage.getItem('region'))
    });

    const formRef = useRef<Form>(null);

    useEffect(() => {
        if (ppe) {
            setCurrentPpe({...ppe});
        }
    }, [ppe]);

    const handleApply = () => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            var trimmedData = {...currentPpe, name:currentPpe.name?.trim()}
            onApply(trimmedData);
        }
    };

    const saveButtonOptions = {
        text: 'Save',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleApply,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onHiding,
    };
    const mandatoryButtonGroupItems = [
        { text: "Yes", key: true },
        { text: "No", key: false }
    ];
    const onMandatoryOptionChanged = (e: SelectionChangedEvent) => {
        setCurrentPpe({
            ...currentPpe,
            isMandatory: e.addedItems[0].key
        });
    };

    return (
        <Popup
            width={'25%'}
            height={'100%'}
            visible={visible}
            onHiding={onHiding}
            hideOnOutsideClick={false}
            position={'right'}
            title={currentPpe?.id > 0 ? 'Edit PPE' : 'Add PPE'}
        >
            <Form
                formData={currentPpe}
                ref={formRef}
            >
                <SimpleItem dataField="name"
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                    <Label text="PPE Name" />
                    <RequiredRule message="Name is mandatory" />
                </SimpleItem>
                <SimpleItem dataField="isMandatory">
                    <Label text="Is this Mandatory PPE" />
                    <ButtonGroup
                        items={mandatoryButtonGroupItems}
                        selectedItemKeys={[currentPpe.isMandatory]}
                        onSelectionChanged={onMandatoryOptionChanged}
                        keyExpr="key"
                        stylingMode="outlined"
                    />
                </SimpleItem>
            </Form>
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={cancelButtonOptions}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={saveButtonOptions}
            />

        </Popup>
    );
};

export default AddPpeDialog;
