import React, { useCallback, useEffect, useState } from 'react';
import './hse-acknowledgement.scss';
import { DataGrid, Column, Paging, Pager, SearchPanel, DataGridTypes, Selection } from 'devextreme-react/data-grid';
import { Button, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar'

import { hseAcknowledgementService } from '../../../../api';
import AddHseDialog from './dialogs/add-hse-acknowledgement';
import { ISafeConfirmationButton } from '../../../../components/Customizatons';
import { useLoad, useToast } from '../../../../contexts';
import { IHseAcknowledgement, IApiResponse } from '../../../../interfaces';


const HseAcknowledgement: React.FC = () => {
  const { showLoader } = useLoad();
  const { showErrorToast, showSuccessToast } = useToast();
  const [isAddHseDialogVisible, setIsAddHseDialogVisible] = useState(false);
  const [currentSelectedHse, setCurrentSelectedHse] = useState<IHseAcknowledgement | null>(null);
  const [ppes, setPpes] = useState<IHseAcknowledgement[]>([]);
  const toggleHseDialog = useCallback(() => {
    setIsAddHseDialogVisible(prevState => !prevState);
  }, []);
  const fetchHses = useCallback(async () => {
    showLoader(true);
    try {
      const response = await hseAcknowledgementService.getAllByRegion();
      if (response.success) {
        setPpes(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching HSE Acknowledgements');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, []);

  useEffect(() => {
    fetchHses();
  }, []);
  const addNewHse = () => {
    setCurrentSelectedHse(null);
    setIsAddHseDialogVisible(!isAddHseDialogVisible);
  };

  const handleAddEditHse = useCallback(async (hse: IHseAcknowledgement) => {
    showLoader(true);
    try {
      let response: IApiResponse<IHseAcknowledgement>;
      if (hse.id > 0) {
        // Update existing hse
        response = await hseAcknowledgementService.update(hse.id.toString(), hse);
        if (response.success) {
          setPpes(currentHses =>
            currentHses.map(a =>
              a.id === hse.id ? response.data : a // Update the specific hse
            )
          );
          showSuccessToast('HSE Acknowledgement updated successfully!');
          setIsAddHseDialogVisible(false);
          fetchHses();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to update the HSE Acknowledgement: ${response.errors.join('; ')}`);
        }
      } else {
        // Create new HSE
        response = await hseAcknowledgementService.create(hse);
        if (response.success) {
          setPpes(currentHses => [...currentHses, response.data]); // Add new hse
          showSuccessToast('HSE Acknowledgement added successfully!');
          setIsAddHseDialogVisible(false);
          fetchHses();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to add the HSE Acknowledgement: ${response.errors.join('; ')}`);
        }
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast, showSuccessToast]);

  const handleDeleteHse = useCallback((hseId: number) => {
    showLoader(true);
    hseAcknowledgementService.delete(hseId.toString()).then(response => {
      if (response.success) {
        // Remove the hse from the list
        setPpes(currentHses =>
          currentHses.filter(hse => hse.id !== hseId)
        );
        showSuccessToast('HSE Acknowledgement deleted successfully!');
        fetchHses();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to delete the HSE Acknowledgement: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast]);
  const handleActivateHse = useCallback((hseId: number, isActive: boolean) => {
    showLoader(true);
    hseAcknowledgementService.activate(hseId.toString(), !isActive).then(response => {
      if (response.success) {
        setPpes(currentHses => {
          var ppeMatch = currentHses.find(a => a.id == hseId);
          if (ppeMatch) ppeMatch.isActive = !ppeMatch?.isActive
          return currentHses;
        }
        );
        showSuccessToast(`HSE Acknowledgement ${isActive ? 'Disabled' : 'Enabled'} successfully!`);
        fetchHses();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to Enable/Disable the HSE Acknowledgement: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast, ppes]);
  const renderTitle = useCallback(() => {
    return <p className='section-title'><b>HSE Acknowledgement</b></p>;
  }, []);
  const addButtonOptions = {
    icon: 'add',
    text: 'Add New',
    stylingMode: "outlined",
    onClick: addNewHse
  }


  const onCellClick = useCallback((data: DataGridTypes.CellClickEvent) => {
    setCurrentSelectedHse(data.data);
  }, []);
  const cellRender = (data: DataGridTypes.ColumnCellTemplateData) => {
    return <div>
      <Button
        text="Edit"
        type="default"
        stylingMode="text"
        onClick={() => {
          setCurrentSelectedHse(data.data);
          toggleHseDialog();
        }}
        disabled={!data.data.isActive}
      />
      <ISafeConfirmationButton
        text={data.data.isActive ? 'Disable' : 'Enable'}
        type="default"
        componentName={HseAcknowledgement.name}
        stylingMode="text"
        item={data.data}
        onConfirm={() => handleActivateHse(data.data.id, data.data.isActive)}
      />
      <ISafeConfirmationButton
        text="Delete"
        type="default"
        componentName={HseAcknowledgement.name}
        stylingMode="text"
        item={data.data}
        onConfirm={() => handleDeleteHse(data.data.id)}
      />
    </div>
  };

  const rowPrepared = useCallback((e: DataGridTypes.RowPreparedEvent) => {
    if (e.rowType === "data") {
      if (!e.data.isActive) {
        e.rowElement.classList.add("inactive-row");
      }
    }
  }, []);
  return (<>
    <div className='hse-acknowledgement-container'>
      <Toolbar className='section-toolbar'>
        <Item
          location='before'
          render={renderTitle}
        ></Item>
        <Item
          location='after'
          widget="dxButton"
          options={addButtonOptions}
        ></Item>
      </Toolbar>
      <DataGrid
        dataSource={ppes}
        keyExpr="id"
        showBorders={true}
        allowColumnReordering={true}
        height={'80vh'}
        onCellClick={onCellClick}
        onRowPrepared={rowPrepared}>
        <Selection mode="single" />
        <Paging defaultPageSize={10} />
        <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showNavigationButtons displayMode='compact' showInfo={true} />

        <Column dataField="name" caption="Name" />

        <Column dataField="createdDate" caption="Created Date" dataType="date" format={"dd MMM yyyy"} />

        <Column cssClass='actions-column' caption="Actions" cellRender={cellRender}>

        </Column>
        <SearchPanel placeholder={'Search here ...'} width={'auto'} visible={true} searchVisibleColumnsOnly />
      </DataGrid>
    </div>
    {isAddHseDialogVisible && <AddHseDialog
      visible={isAddHseDialogVisible}
      onHiding={toggleHseDialog}
      onApply={handleAddEditHse}
      hse={currentSelectedHse}
    />
    }
  </>
  );
};

export default HseAcknowledgement;
