import React, { useEffect, useRef, useState } from 'react';
import { Form, Popup, TextBox } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/popup';
import { SimpleItem, RequiredRule, Label } from 'devextreme-react/form';
import ArrayStore from 'devextreme/data/array_store';
import { IJobHazard } from '../../../../../interfaces'
import { IPreworkInspection } from '../../../../../interfaces'
import { ISiteCondition } from '../../../../../interfaces'

export interface AddSiteConditionsDialogProps {
    visible: boolean;
    siteCondition: ISiteCondition | null;
    onHiding: () => void;
    onApply: (siteCondition: ISiteCondition) => void;
    jobHazardsDataSource: IJobHazard[];
    preworkInspectionsDataSource: IPreworkInspection[];
}

const AddSiteConditionsDialog: React.FC<AddSiteConditionsDialogProps> = ({
    visible,
    onHiding,
    onApply,
    siteCondition,
    jobHazardsDataSource,
    preworkInspectionsDataSource
}) => {
    const [currentSiteCondition, setCurrentSiteCondition] = useState<ISiteCondition>({
        id: 0,
        name: '',
        jobHazards: [],
        preworkInspections: [],
        createdDate: new Date(),
        isActive: true,
        regionId: Number(localStorage.getItem('region'))
    });

    const formRef = useRef<Form>(null);

    useEffect(() => {
        if (siteCondition) {
            setCurrentSiteCondition({...siteCondition});
        }
    }, [siteCondition]);

    const handleApply = () => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            var trimmedData = {...currentSiteCondition, name:currentSiteCondition.name?.trim()}
            onApply(trimmedData);
        }
    };

    const saveButtonOptions = {
        text: 'Save',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleApply,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onHiding,
    };
    return (
        <Popup
            width={'25%'}
            height={'100%'}
            visible={visible}
            onHiding={onHiding}
            hideOnOutsideClick={false}
            position={'right'}
            title={currentSiteCondition?.id > 0 ? 'Edit Site Condition' : 'Add Site Condition'}
        >
            <Form
                formData={currentSiteCondition}
                ref={formRef}
            >
                <SimpleItem dataField="name"
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton:true
                    }}>
                    <Label text="Site Condition" />
                    <RequiredRule message="Name is mandatory" />
                </SimpleItem>
                <SimpleItem
                    dataField="jobHazards"
                    editorType="dxTagBox"
                    editorOptions={{
                        dataSource: new ArrayStore({
                            data: jobHazardsDataSource,
                            key: "id",
                        }),
                        placeholder: "Please select Job Hazards",
                        displayExpr: "name",
                        valueExpr: "id",
                        searchEnabled: true,
                        hideSelectedItems: true,
                        onValueonChanged: (e: any) => setCurrentSiteCondition({ ...currentSiteCondition, jobHazards: e.value }),
                    }}
                >
                    <Label text="Job Hazards" />
                </SimpleItem>
                <SimpleItem
                    dataField="preworkInspections"
                    editorType="dxTagBox"
                    editorOptions={{
                        dataSource: new ArrayStore({
                            data: preworkInspectionsDataSource,
                            key: "id",
                        }),
                        placeholder: "Please select Pre-Work Inspections",
                        displayExpr: "name",
                        valueExpr: "id",
                        searchEnabled: true,
                        hideSelectedItems: true,
                        onValueonChanged: (e: any) => setCurrentSiteCondition({ ...currentSiteCondition, preworkInspections: e.value }),
                    }}
                >
                    <Label text="Pre-Work Inspections" />
                </SimpleItem>
            </Form>
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={cancelButtonOptions}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={saveButtonOptions}
            />

        </Popup>
    );
};

export default AddSiteConditionsDialog;
