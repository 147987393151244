import React, { useCallback, useEffect, useState } from 'react';
import './cha-acknowledgement.scss';
import { Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar';
import HtmlEditor, { Toolbar as HtmlToolbar, Item as HtmlItem } from 'devextreme-react/html-editor';
import { useLoad, useToast } from '../../../../contexts';
import { IChaAcknowledgement } from '../../../../interfaces/cha/IChaAcknowledgement';
import { chaAcknowledgementService } from '../../../../api/cha/ChaAcknowledgement';
import { debounce } from 'lodash';

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
const fontSizeOptions = {
  inputAttr: {
    'aria-label': 'Font size',
  },
};
const fontFamilyOptions = {
  inputAttr: {
    'aria-label': 'Font family',
  },
};

const ChaAcknowledgement: React.FC = () => {
  const { showLoader } = useLoad();
  const { showErrorToast, showSuccessToast } = useToast();
  const [acknowledgement, setAcknowledgement] = useState<IChaAcknowledgement>({
    id:0,
    createdDate:new Date(),
    description:'',
    regionId:Number(localStorage.getItem('region'))
  });

  const fetchAcknowledgement = useCallback(async () => {
    showLoader(true);
    try {
      const response = await chaAcknowledgementService.getAllByRegion();
      if (response.success) {
        if(response.data[0]) setAcknowledgement(response.data[0]);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Cha Acknowledgement');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, []);

  useEffect(() => {
    fetchAcknowledgement();
  }, []);

  const renderTitle = useCallback(() => {
    return <p className='section-title'><b>CHA Acknowledgement</b></p>;
  }, []);
  const updateAcknowledgement = async (acknowledgement:IChaAcknowledgement) => {
    if(!acknowledgement.description) return;
    try {
      const response = await chaAcknowledgementService.create(acknowledgement);
      if (!response.success) {
        console.error(response.errors);
        showErrorToast('Error while saving Cha Acknowledgement');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Debounced API call function
  const debouncedUpdateAcknowledgement = useCallback(
    debounce((acknowledgement) => updateAcknowledgement(acknowledgement), 1000),
    []
  );

  useEffect(()=>{
    debouncedUpdateAcknowledgement(acknowledgement);
  },[acknowledgement])

  return (<>
    <div className='cha-ack-container'>
      <Toolbar className='section-toolbar'>
        <Item
          location='before'
          render={renderTitle}
        ></Item>
      </Toolbar>
      <HtmlEditor
        style={{ minHeight: '40vh', maxHeight: '75vh' }}
        value={acknowledgement?.description}
        valueType={'html'}
        onValueChanged={(e:any) => setAcknowledgement({...acknowledgement, description:e.value?? ''})}
      >
        <HtmlToolbar>
          <HtmlItem name="undo" />
          <HtmlItem name="redo" />
          <HtmlItem name="separator" />
          <HtmlItem
            name="size"
            acceptedValues={sizeValues}
            options={fontSizeOptions}
          />
          <HtmlItem
            name="font"
            acceptedValues={fontValues}
            options={fontFamilyOptions}
          />
          <HtmlItem name="separator" />
          <HtmlItem name="bold" />
          <HtmlItem name="italic" />
          <HtmlItem name="strike" />
          <HtmlItem name="underline" />
          <HtmlItem name="separator" />
          <HtmlItem name="alignLeft" />
          <HtmlItem name="alignCenter" />
          <HtmlItem name="alignRight" />
          <HtmlItem name="alignJustify" />
          <HtmlItem name="separator" />
          <HtmlItem name="orderedList" />
          <HtmlItem name="bulletList" />
          <HtmlItem name="separator" />
          <HtmlItem name="color" />
        </HtmlToolbar>
      </HtmlEditor>
    </div>

  </>
  );
};

export default ChaAcknowledgement;
