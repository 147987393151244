import React, { createContext, useContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { IRegion } from '../interfaces';
import { locationService } from '../api';


interface RegionContextType {
  region: string;
  setRegion: (value: string) => void;
  regions: IRegion[];
  fetchRegions: () => void;
  isLoading: boolean;
}

const RegionContext = createContext<RegionContextType | undefined>(undefined);

interface RegionProviderProps {
  children: ReactNode;
}

export const useRegion = (): RegionContextType => {
  const context = useContext(RegionContext);
  if (context === undefined) {
    throw new Error('useRegion must be used within a RegionProvider');
  }
  return context;
};

export const RegionProvider: React.FC<RegionProviderProps> = ({ children }) => {
  const [region, setRegionState] = useState<string>(localStorage.getItem('region') || '');
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setRegion = useCallback((value: string) => {
    setRegionState(value);
    localStorage.setItem('region', value);
  }, []);

  const fetchRegions = useCallback(() => {
    setIsLoading(true);
    locationService.getAll({isActive: true}).then(response => {
      if (response.success) {
        setRegions(response.data);
      } else {
        console.error(response.errors);
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'region') {
        setRegionState(event.newValue || '');
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <RegionContext.Provider value={{ region, setRegion, regions, fetchRegions, isLoading }}>
      {children}
    </RegionContext.Provider>
  );
};
