import { Button, Toolbar, DateBox, SelectBox } from "devextreme-react";
import { Chart, Series, ArgumentAxis, ValueAxis, Label, Format, CommonSeriesSettings, Export, Title, Legend, Tooltip } from 'devextreme-react/chart';
import { useLoad, useToast } from "../../../../contexts";
import './jha-analysis.scss';
import { Item } from "devextreme-react/toolbar";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IJhaReportInfo, IJobCountByCTC } from "../../../../interfaces";
import { reportsService } from "../../../../api/reports";
import moment from "moment";

export default function JhaAnalysis() {
  const { showErrorToast } = useToast();
  const { showLoader } = useLoad();
  const [countByCtc, setCountByCtc] = useState<IJobCountByCTC[]>([]);
  const [incidentJobCountByCtc, setIncidentJobCountByCtc] = useState<IJobCountByCTC[]>([]);
  const [jhaReportInfo, setJhaReportInfo] = useState<IJhaReportInfo[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000)); // Default to 90 days
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [presetDateRange, setPresetDateRange] = useState<string>('Last 90 days');

  const fetJhaReportsInfo = useCallback(async () => {
    showLoader(true);
    try {
      const response = await reportsService.getJhaReportsInfo(
        moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
        moment(endDate).format('YYYY-MM-DDTHH:mm:ss')
      );
      if (response.success) {
        setJhaReportInfo(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Job Reports Info');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, [startDate, endDate, showLoader, showErrorToast]);

  const fetchJobCountByCTC = useCallback(async () => {
    showLoader(true);
    try {
      const response = await reportsService.getJobCountByCTC(
        moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
        moment(endDate).format('YYYY-MM-DDTHH:mm:ss')
      );
      if (response.success) {
        const sortedData = response.data.sort((a, b) => a.count - b.count); // Sort data in descending order by count
        setCountByCtc(sortedData);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Job Counts By CTC');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, [startDate, endDate, showLoader, showErrorToast]);

  const refreshReports = useCallback(() => {
    fetchJobCountByCTC();
    fetJhaReportsInfo();
  }, [fetchJobCountByCTC, fetJhaReportsInfo]);

  useEffect(() => {
    refreshReports();
  }, [presetDateRange, refreshReports]);

  const handlePresetDateRangeChange = useCallback((e: any) => {
    const value = e.value;
    setPresetDateRange(value);
    const today = new Date();
    switch (value) {
      case 'Last 1 day':
        setStartDate(new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case 'Last 7 days':
        setStartDate(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case 'Last 30 days':
        setStartDate(new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case 'Last 90 days':
        setStartDate(new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case 'Last 12 months':
        setStartDate(new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()));
        setEndDate(today);
        break;
      default:
        setStartDate(new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000));
        setEndDate(new Date());
        break;
    }
  }, []);

  const activityCounts = useMemo(() => {
    const counts = jhaReportInfo.reduce((acc, curr) => {
      acc[curr.activityName] = (acc[curr.activityName] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    return Object.keys(counts).map(activityName => ({
      activityName,
      count: counts[activityName]
    })).sort((a, b) => b.count - a.count);
  }, [jhaReportInfo]);

  // Calculate incidentJobCountByCtc
  useEffect(() => {
    const uniqueIncidentJobs = jhaReportInfo.filter(job => job.incidetId !== null);
    const uniqueJobIds = new Set();
    const uniqueJobs = uniqueIncidentJobs.filter(job => {
      if (uniqueJobIds.has(job.jhaId)) {
        return false;
      }
      uniqueJobIds.add(job.jhaId);
      return true;
    });
  
    const counts = uniqueJobs.reduce((acc, curr) => {
      acc[curr.ctc] = (acc[curr.ctc] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
  
    const incidentJobCountData = Object.keys(counts).map(ctc => ({
      ctc,
      count: counts[ctc]
    })).sort((a, b) => b.count - a.count);
  
    setIncidentJobCountByCtc(incidentJobCountData);
  }, [jhaReportInfo]);
  

  const renderTitle = useCallback(() => {
    return <p className='section-title'><b>JHA Analysis</b></p>;
  }, []);

  function customizeTooltip(arg: { valueText: string; }) {
    return {
      text: `Count: ${arg.valueText}`,
    };
  }

  return (
    <div className='jha-analysis-container'>
      <Toolbar className='section-toolbar'>
        <Item
          location='after'
          locateInMenu='auto'
          widget='dxDateBox'
          options={{
            value: startDate,
            onValueChanged: (e: any) => setStartDate(e.value),
            placeholder: 'Start Date'
          }}
        />
        <Item
          location='after'
          locateInMenu='auto'
          widget='dxDateBox'
          options={{
            value: endDate,
            onValueChanged: (e: any) => setEndDate(e.value),
            placeholder: 'End Date'
          }}
        />
        <Item
          location='after'
          locateInMenu='auto'
          widget='dxSelectBox'
          options={{
            items: ['Last 1 day', 'Last 7 days', 'Last 30 days', 'Last 90 days', 'Last 12 months'],
            value: presetDateRange,
            onValueChanged: handlePresetDateRangeChange,
            placeholder: 'Select Date Range'
          }}
        />
        <Item
          location='after'
          locateInMenu='auto'
          widget='dxButton'
          showText='inMenu'
        >
          <Button
            text='Refresh'
            icon='refresh'
            stylingMode='text'
            onClick={refreshReports}
          />
        </Item>
      </Toolbar>
      <div className="report-section" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <Chart style={{ minHeight: '80vh' }} id="count-by-ctc" dataSource={countByCtc} rotated title="JHAs Count By CTC">
          <ArgumentAxis>
            <Label overlappingBehavior="stagger" />
          </ArgumentAxis>
          <CommonSeriesSettings barPadding={0.2} />
          <Series
            type="bar"
            argumentField="ctc"
            valueField="count"
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
            barWidth={30} // Adjust bar width
          >
            <Label visible={true}>
              <Format type="fixedPoint" precision={0} />
            </Label>
          </Series>
          <Legend visible={false} />
          <Export enabled={true} />
        </Chart>
        <Chart id="activity-counts-chart" dataSource={activityCounts}>
          <Title text="Activity-wise Count" />
          <CommonSeriesSettings argumentField="activityName" type="bar" />
          <Series
            valueField="count"
            name="Count"
            color="#1e90ff"
            label={{
              visible: true,
              position: 'inside', // You can also use 'inside' if you prefer
              customizeText: (arg) => `${arg.valueText}`
            }}
          />
          <ArgumentAxis>
            <Label overlappingBehavior="stagger" />
          </ArgumentAxis>
          <ValueAxis>
            <Label>
              <Format type="fixedPoint" precision={0} />
            </Label>
            <Title text="Count" />
          </ValueAxis>
          <Legend verticalAlignment="top" horizontalAlignment="center" itemTextPosition="right" />
          <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
          <Export enabled />
        </Chart>
        <Chart style={{ minHeight: '80vh' }} id="incident-job-count-by-ctc" dataSource={incidentJobCountByCtc} rotated title="JHAs with Incidents By CTC">
          <ArgumentAxis>
            <Label overlappingBehavior="stagger" />
          </ArgumentAxis>
          <CommonSeriesSettings barPadding={0.2} />
          <Series
            type="bar"
            argumentField="ctc"
            valueField="count"
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
            barWidth={30} // Adjust bar width
          >
            <Label visible={true}>
              <Format type="fixedPoint" precision={0} />
            </Label>
          </Series>
          <Legend visible={false} />
          <Export enabled={true} />
        </Chart>
      </div>
    </div>
  );
}
