import React, { useCallback, useEffect, useState } from 'react';
import './site-conditions.scss';
import { DataGrid, Column, Paging, Pager, SearchPanel, DataGridTypes, Selection } from 'devextreme-react/data-grid';
import { Button, TagBox, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar';
import { ISafeConfirmationButton } from '../../../../components/Customizatons';
import AddSiteConditionsDialog from './dialogs/add-site-conditions';
import { useLoad, useToast } from '../../../../contexts';
import { IPreworkInspection, ISiteCondition, IJobHazard, IApiResponse } from '../../../../interfaces';
import { siteConditionService, jobHazardsService, inspectionService } from '../../../../api';



const SiteConditions: React.FC = () => {
  const { showLoader } = useLoad();
  const { showErrorToast,showSuccessToast } = useToast();
  const [isAddSiteConditionsDialogVisible, setIsAddSiteConditionsDialogVisible] = useState(false);
  const [currentSelectedSiteCondition, setCurrentSelectedSiteCondition] = useState<ISiteCondition | null>(null);
  const [siteConditions, setSiteConditions] = useState<ISiteCondition[]>([]);
  const [jobHazards, setJobHazards] = useState<IJobHazard[]>([]);
  const [preworkInspections, setPreworkInspections] = useState<IPreworkInspection[]>([]);

  const toggleSiteConditionsDialog = useCallback(() => {
    setIsAddSiteConditionsDialogVisible(prevState => !prevState);
  }, []);
  const fetchSiteConditions = useCallback(async () => {
    showLoader(true);
    try {
      const response = await siteConditionService.getAllByRegion();
      if (response.success) {
        setSiteConditions(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Site Conditions');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast]);
  const fetchJobHazards = useCallback(async () => {
    try {
      const response = await jobHazardsService.getAllByRegion({isActive:true});
      if (response.success) {
        setJobHazards(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Job Hazards');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } 
  }, []);
  const fetchPreWorkInspections = useCallback(async () => {
    try {
      const response = await inspectionService.getAllByRegion({isActive:true});
      if (response.success) {
        setPreworkInspections(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Pre-Work Inspections');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } 
  }, []);
  useEffect(() => {
    fetchSiteConditions();
    fetchJobHazards();
    fetchPreWorkInspections();
  }, [fetchSiteConditions]);
  const addNewSiteCondition = () => {
    setCurrentSelectedSiteCondition(null);
    setIsAddSiteConditionsDialogVisible(!isAddSiteConditionsDialogVisible);
  };

  const handleAddEditSiteCondition = useCallback(async (siteCondition: ISiteCondition) => {
    showLoader(true);
    try {
      let response: IApiResponse<ISiteCondition>;
      if (siteCondition.id > 0) {
        // Update existing siteCondition
        response = await siteConditionService.update(siteCondition.id.toString(), siteCondition);
        if (response.success) {
          setSiteConditions(currentSiteConditions => 
            currentSiteConditions.map(a => 
              a.id === siteCondition.id ? response.data : a // Update the specific siteCondition
            )
          );
          showSuccessToast('Site Condition updated successfully!');
          setIsAddSiteConditionsDialogVisible(false);
          fetchSiteConditions();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to update the Site Condition: ${response.errors.join('; ')}`);
        }
      } else {
        // Create new siteCondition
        response = await siteConditionService.create(siteCondition);
        if (response.success) {
          setSiteConditions(currentSiteConditions => [...currentSiteConditions, response.data]); // Add new siteCondition
          showSuccessToast('Site Condition added successfully!');
          setIsAddSiteConditionsDialogVisible(false);
          fetchSiteConditions();
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to add the Site Condition: ${response.errors.join('; ')}`);
        }
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast, showSuccessToast]);
  
  const handleDeleteSiteCondition = useCallback((siteConditionId: number) => {
    showLoader(true);
    siteConditionService.delete(siteConditionId.toString()).then(response => {
      if (response.success) {
        // Remove the siteCondition from the list
        setSiteConditions(currentSiteConditions => 
          currentSiteConditions.filter(siteCondition => siteCondition.id !== siteConditionId)
        );
        showSuccessToast('Site Condition deleted successfully!');
        fetchSiteConditions();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to delete the Site Condition: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast]);
  
  const handleActivateSiteCondition = useCallback((siteConditionId: number, isActive:boolean) => {
    showLoader(true);
    siteConditionService.activate(siteConditionId.toString(),!isActive).then(response => {
      if (response.success) {
        setSiteConditions(currentSiteConditions => 
          {
            var siteConditionMatch = currentSiteConditions.find(a => a.id==siteConditionId);
            if(siteConditionMatch) siteConditionMatch.isActive =  !siteConditionMatch?.isActive
            return currentSiteConditions;
          }
        );
        showSuccessToast(`Site Condition ${isActive ?'Disabled':'Enabled'} successfully!`);
        fetchSiteConditions();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to Enable/Disable the Site Condition: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast,siteConditions]);
  const renderTitle = useCallback(() => {
    return <p className='section-title'><b>Site Conditions</b></p>;
    }, []);
  const addButtonOptions = {
    icon: 'add',
    text: 'Add New',
    stylingMode: "outlined",
    onClick: addNewSiteCondition
  }


  const onCellClick = useCallback((data: DataGridTypes.CellClickEvent) => {
    setCurrentSelectedSiteCondition(data.data);
  }, []);
  const cellRender = (data: DataGridTypes.ColumnCellTemplateData) => {
    return <div>
      <Button
        text="Edit"
        type="default"
        stylingMode="text"
        onClick={() => {
          setCurrentSelectedSiteCondition(data.data);
          toggleSiteConditionsDialog();
        }}
        disabled={!data.data.isActive}
      />
      <ISafeConfirmationButton
        text={data.data.isActive ? 'Disable': 'Enable'}
        type="default"
        stylingMode="text"
        componentName={SiteConditions.name}
        item={data.data}
        onConfirm={() => handleActivateSiteCondition(data.data.id,data.data.isActive)}

      />
      <ISafeConfirmationButton
        text="Delete"
        type="default"
        stylingMode="text"
        componentName={SiteConditions.name}
        item={data.data}
        onConfirm={() => handleDeleteSiteCondition(data.data.id)}
      />
      
    </div>
  };
  const jobHazardsCellRender = useCallback((data: DataGridTypes.ColumnCellTemplateData) => {
    return <TagBox
      dataSource={jobHazards}
      inputAttr={{ 'aria-label': 'JobHazard' }}
      displayExpr="name"
      valueExpr="id"
      defaultValue={data.value}
      disabled
      placeholder=''
    />
  },[jobHazards]);
  const rowPrepared = useCallback((e: DataGridTypes.RowPreparedEvent) => {
    if (e.rowType === "data") {
      if (!e.data.isActive) {
        e.rowElement.classList.add("inactive-row");
      }
    }
  }, []);
  return (<>
    <div className='siteConditions-container'>
      <Toolbar className='section-toolbar'>
        <Item
          location='before'
          render={renderTitle}
        ></Item>
        <Item
          location='after'
          widget="dxButton"
          options={addButtonOptions}
        ></Item>
      </Toolbar>
      <DataGrid
        dataSource={siteConditions}
        keyExpr="id"
        showBorders={true}
        allowColumnReordering={true}
        height={'80vh'}
        onCellClick={onCellClick}
        onRowPrepared={rowPrepared}>
        <Selection mode="single" />
        <Paging defaultPageSize={10} />
        <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showNavigationButtons displayMode='compact' showInfo={true} />

        <Column dataField="name" caption="Name" />
        <Column dataField="jobHazards" allowSearch caption="Job Hazards"
          cellRender={jobHazardsCellRender}
        />
        <Column dataField="createdDate" caption="Created Date" dataType="date" format={"dd MMM yyyy"} />

        <Column cssClass='actions-column' caption="Actions" cellRender={cellRender}>

        </Column>
        <SearchPanel placeholder={'Search here ...'} width={'auto'} visible={true} searchVisibleColumnsOnly />
      </DataGrid>
    </div>
    {isAddSiteConditionsDialogVisible && <AddSiteConditionsDialog
      visible={isAddSiteConditionsDialogVisible}
      onHiding={toggleSiteConditionsDialog}
      onApply={handleAddEditSiteCondition}
      siteCondition={currentSelectedSiteCondition}
      jobHazardsDataSource={jobHazards}
      preworkInspectionsDataSource={preworkInspections}
    />
    }
    
  </>
  );
};

export default SiteConditions;
