import React, { useEffect, useRef, useState } from 'react';
import { Form, Popup } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/popup';
import { SimpleItem, RequiredRule, Label } from 'devextreme-react/form';
import { IRegion } from '../../../../../interfaces'

export interface AddRegionDialogProps {
    visible: boolean;
    region: IRegion | null;
    onHiding: () => void;
    onApply: (region: IRegion) => void;
}

const AddRegionDialog: React.FC<AddRegionDialogProps> = ({
    visible,
    onHiding,
    onApply,
    region
}) => {
    const [currentRegion, setCurrentPpe] = useState<IRegion>({
        id: 0,
        name: '',
        createdDate: new Date(),
        isActive: true,
        code:''
    });

    const formRef = useRef<Form>(null);

    useEffect(() => {
        if (region) {
            setCurrentPpe({...region});
        }
    }, [region]);

    const handleApply = () => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            var trimmedData = {...currentRegion, name:currentRegion.name?.trim()}
            onApply(trimmedData);
        }
    };

    const saveButtonOptions = {
        text: 'Save',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleApply,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onHiding,
    };
   

    return (
        <Popup
            width={'25%'}
            height={'100%'}
            visible={visible}
            onHiding={onHiding}
            hideOnOutsideClick={false}
            position={'right'}
            title={currentRegion?.id > 0 ? 'Edit Region/Country' : 'Add Region/Country'}
        >
            <Form
                formData={currentRegion}
                ref={formRef}
            >
                <SimpleItem dataField="name"
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                    <Label text="Region/Country Name" />
                    <RequiredRule message="Name is mandatory" />
                </SimpleItem>
                <SimpleItem dataField="code"
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                    <Label text="Region/Country Code" />
                    <RequiredRule message="Code is mandatory" />
                </SimpleItem>
                
            </Form>
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={cancelButtonOptions}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={saveButtonOptions}
            />

        </Popup>
    );
};

export default AddRegionDialog;
