import React, { useCallback, useState } from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { SelectBox } from "devextreme-react/select-box";
import { IRegion } from "../../interfaces";
import { LoadIndicator } from "devextreme-react";
import { useNavigate } from "react-router-dom";
import "./regiondialog.scss";

export interface RegionDialogProps {
  visible: boolean;
  regions: IRegion[];
  onHiding: () => void;
  onApply: (region: Number | null) => void;
  activeRegion: Number | null;
  loading: boolean;
}

const RegionDialog: React.FC<RegionDialogProps> = ({
  visible,
  onHiding,
  onApply,
  regions,
  activeRegion,
  loading,
}) => {
  const [selectedRegion, setSelectedRegion] = useState<Number | null>(activeRegion);
  const navigate = useNavigate();

  const applySelection = useCallback(() => {
    onApply(selectedRegion);
    navigate("/jha");
    onHiding();
  }, [onApply, selectedRegion, onHiding]);

  const getApplyButtonOptions = useCallback(
    () => ({
      stylingMode: "contained",
      text: "Apply",
      onClick: applySelection,
    }), [applySelection]);
  
  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      dragEnabled={true}
      hideOnOutsideClick={false}
      title={"Please Select Your Country"}
      width={572}
      height={280}
      container=".dx-viewport"
    >
      <p><b>Select Region</b></p>
      {loading ? (
        <div className="region-loading-indicator">
          <LoadIndicator visible={loading} />
        </div>
      ) : (
        <SelectBox
          items={regions}
          displayExpr="name"
          valueExpr="id"
          value={selectedRegion}
          onValueChanged={(e) => setSelectedRegion(e.value)}
          placeholder="Select region"
          searchEnabled
        />
      )}

      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={getApplyButtonOptions()}
        disabled={!selectedRegion}
      />
    </Popup>
  );
};

export default RegionDialog;
