import React, { useCallback, useEffect, useState } from 'react';
import './inspection-management.scss';
import { DataGrid, Column, Paging, Pager, SearchPanel, DataGridTypes, Selection } from 'devextreme-react/data-grid';
import { Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar'
import { ISafeConfirmationButton } from '../../../../components/Customizatons';
import { useLoad, useToast } from '../../../../contexts';
import { IPreworkInspection } from '../../../../interfaces';
import { inspectionService } from '../../../../api/inspections';

const InspectionManagement: React.FC = () => {
  const { showLoader } = useLoad();
  const { showErrorToast, showSuccessToast } = useToast();
  const [inspections, setInspections] = useState<IPreworkInspection[]>([]);

  const fetchInspections = useCallback(async () => {
    showLoader(true);
    try {
      const response = await inspectionService.getAllByRegion();
      if (response.success) {
        setInspections(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Inspections');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, []);

  useEffect(() => {
    fetchInspections();
  }, []);

  const handleActivateInspection = useCallback((inspectionId: number, isActive: boolean) => {
    showLoader(true);
    inspectionService.activate(inspectionId.toString(), !isActive).then(response => {
      if (response.success) {
        setInspections(currentInspections => {
          var inspectionMatch = currentInspections.find(a => a.id == inspectionId);
          if (inspectionMatch) inspectionMatch.isActive = !inspectionMatch?.isActive
          return currentInspections;
        }
        );
        showSuccessToast(`Inspection ${isActive ? 'Disabled' : 'Enabled'} successfully!`);
        fetchInspections();
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to Enable/Disable the Inspection: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast, inspections]);
  const renderTitle = useCallback(() => {
    return <p className='section-title'><b>Inspections Management</b></p>;
  }, []);


  const cellRender = (data: DataGridTypes.ColumnCellTemplateData) => {
    return <div>
      
      <ISafeConfirmationButton
        text={data.data.isActive ? 'Disable' : 'Enable'}
        type="default"
        componentName={InspectionManagement.name}
        stylingMode="text"
        item={data.data}
        onConfirm={() => handleActivateInspection(data.data.id, data.data.isActive)}
      />
    </div>
  };

  const rowPrepared = useCallback((e: DataGridTypes.RowPreparedEvent) => {
    if (e.rowType === "data") {
      if (!e.data.isActive) {
        e.rowElement.classList.add("inactive-row");
      }
    }
  }, []);
  return (<>
    <div className='inspection-management-container'>
      <Toolbar className='section-toolbar'>
        <Item
          location='before'
          render={renderTitle}
        ></Item>
        
      </Toolbar>
      <DataGrid
        dataSource={inspections}
        keyExpr="id"
        showBorders={true}
        allowColumnReordering={true}
        height={'80vh'}
        onRowPrepared={rowPrepared}>
        <Selection mode="single" />
        <Paging defaultPageSize={10} />
        <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showNavigationButtons displayMode='compact' showInfo={true} />

        <Column dataField="name" caption="Name" />


        <Column cssClass='actions-column' caption="Actions" cellRender={cellRender}>

        </Column>
        <SearchPanel placeholder={'Search here ...'} width={'auto'} visible={true} searchVisibleColumnsOnly />
      </DataGrid>
    </div>
  </>
  );
};

export default InspectionManagement;
