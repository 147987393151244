import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  DataGrid,
  PieChart,
  Toolbar,
  DropDownBox,
  List,
} from "devextreme-react";
import {
  Chart,
  Series,
  Legend,
  Label,
  Format,
  Title,
  CommonSeriesSettings,
  Tooltip,
  ArgumentAxis,
  ValueAxis,
  Export,
} from "devextreme-react/chart";
import { useLoad, useToast } from "../../../../contexts";
import { Item } from "devextreme-react/toolbar";
import {
  ICTCInfo,
  IInspectionDetails,
  IPreworkInspection,
} from "../../../../interfaces";
import { reportsService } from "../../../../api/reports";
import {
  Column,
  DataGridTypes,
  MasterDetail,
  Pager,
  Paging,
} from "devextreme-react/cjs/data-grid";
import DetailTemplate from "./components/inspection-answers-detail-count/inspection-answers-detail-count";
import { inspectionService } from "../../../../api";
import moment from "moment";
import { erpService } from "../../../../api/common/ErpService";
import {
  CAPTIONS,
  TIME_PERIOD,
  TOAST_MESSAGES,
} from "../../../../utils/constants";
import "./inspection-analysis.scss";

export default function InspectionAnalysis() {
  const { showErrorToast } = useToast();
  const { showLoader } = useLoad();
  const {
    errorFetchingInspectionData,
    networkServerError,
    errorFetchingInspections,
    errorFetchingCtc,
  } = TOAST_MESSAGES;
  const {
    passedStatus,
    failedStatus,
    startDateCaption,
    endDateCaption,
    selectDateRange,
    selectCTC,
    refresh,
    passFailPercentageofQuestions
  } = CAPTIONS;
  const { last1Day, last7Days, last30Days, last90Days, last12Months } =
    TIME_PERIOD;

  const [inspections, setInspections] = useState<IPreworkInspection[]>([]);
  const [inspectionData, setInspectionData] = useState<IInspectionDetails[]>([]);
  const [overallStats, setOverallStats] = useState<
    { status: string; count: number }[]
  >([]);
  const [selectedInspections, setSelectedInspections] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000)
  ); // Default to 90 days
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [presetDateRange, setPresetDateRange] = useState<string>(last90Days);
  const [ctcList, setCtcList] = useState<ICTCInfo[]>([]);
  const [selectedCtcs, setSelectedCtcs] = useState<string[]>([]);

  const fetchInspectionData = useCallback(async () => {
    showLoader(true);
    try {
      const response = await reportsService.getSubmittedInspections(
        moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
        moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
        selectedCtcs.join(",")
      );
      if (response.success) {
        const data = response.data as IInspectionDetails[];
        setInspectionData(data);

        // Calculate overall pass/fail counts
        const passed = data.filter((item) => item.isPassed).length;
        const failed = data.filter((item) => !item.isPassed).length;
        setOverallStats([
          { status: passedStatus, count: passed },
          { status: failedStatus, count: failed },
        ]);

        // By default, select all inspections
        const allInspectionIds = Array.from(
          new Set(data.map((item) => item.inspectionId))
        );
        setSelectedInspections(allInspectionIds);
      } else {
        console.error(response.errors);
        showErrorToast(errorFetchingInspectionData);
      }
    } catch (error) {
      console.error(error);
      showErrorToast(networkServerError);
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast, startDate, endDate, selectedCtcs]);

  const fetchInspections = useCallback(async () => {
    showLoader(true);
    try {
      const response = await inspectionService.getAllByRegion();
      if (response.success) {
        setInspections(response.data);
      } else {
        console.error(response.errors);
        showErrorToast(errorFetchingInspections);
      }
    } catch (error) {
      console.error(error);
      showErrorToast(networkServerError);
    } finally {
      showLoader(false);
    }
  }, [showLoader, showErrorToast]);
  const fetchCtcs = useCallback(async () => {
    try {
      const response = await erpService.getAllCTCs();
      if (response.success) {
        setCtcList(response.data);
      } else {
        console.error(response.errors);
        showErrorToast(errorFetchingCtc);
      }
    } catch (error) {
      console.error(error);
      showErrorToast(networkServerError);
    }
  }, [showLoader, showErrorToast]);
  const refreshReports = useCallback(() => {
    fetchInspectionData();
  }, [fetchInspectionData]);

  useEffect(() => {
    refreshReports();
  }, [refreshReports]);
  useEffect(() => {
    fetchInspections();
    fetchCtcs();
  }, [fetchInspections, fetchCtcs]);

  const handlePresetDateRangeChange = useCallback((e: any) => {
    const value = e.value;
    setPresetDateRange(value);
    const today = new Date();
    switch (value) {
      case last1Day:
        setStartDate(new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case last7Days:
        setStartDate(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case last30Days:
        setStartDate(new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case last90Days:
        setStartDate(new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000));
        setEndDate(today);
        break;
      case last12Months:
        setStartDate(
          new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())
        );
        setEndDate(today);
        break;
      default:
        setStartDate(new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000));
        setEndDate(new Date());
        break;
    }
  }, []);
  const filteredData = inspectionData.filter((item) =>
    selectedInspections.includes(item.inspectionId)
  );

  const inspectionStats = filteredData.reduce((acc, curr) => {
    const existing = acc.find(
      (item) => item.inspectionId === curr.inspectionId
    );
    if (existing) {
      existing[curr.isPassed ? "passed" : "failed"]++;
    } else {
      acc.push({
        inspectionId: curr.inspectionId,
        name: curr.name,
        passed: curr.isPassed ? 1 : 0,
        failed: curr.isPassed ? 0 : 1,
      });
    }
    return acc;
  }, [] as { inspectionId: number; name: string; passed: number; failed: number }[]);

  // Sort inspectionStats by total count in descending order
  const sortedInspectionStats = useMemo(() => {
    return inspectionStats.sort(
      (a, b) => b.passed + b.failed - (a.passed + a.failed)
    );
  }, [inspectionStats]);

  const [collapsed, setCollapsed] = useState(true);

  const onContentReady = useCallback(
    (e: DataGridTypes.ContentReadyEvent) => {
      if (collapsed) {
        e.component.expandRow(["EnviroCare"]);
        setCollapsed(false);
      }
    },
    [collapsed]
  );

  const rowPrepared = useCallback((e: DataGridTypes.RowPreparedEvent) => {
    if (e.rowType === "data") {
      const plus = document.createElement("i");
      plus.classList.add("dx-icon-add");
      const minus = document.createElement("i");
      minus.classList.add("dx-icon-minus");
      e.rowElement
        .getElementsByClassName("dx-datagrid-group-closed")[0]
        ?.appendChild(plus);
      e.rowElement
        .getElementsByClassName("dx-datagrid-group-opened")[0]
        ?.appendChild(minus);
    }
  }, []);

  function customizeTooltip(arg: { percentText: string; valueText: string }) {
    return {
      text: `Percentage: ${arg.percentText}, Count: ${arg.valueText}`,
    };
  }
  const onSelectedCtcChange = useCallback(
    (e: any) => {
      if (e.name === "selectedItemKeys") {
        setSelectedCtcs(e.value);
      }
    },
    [setSelectedCtcs]
  );
  return (
    <div className="inspection-analysis-container">
      <Toolbar className="section-toolbar">
        <Item
          location="after"
          locateInMenu="auto"
          widget="dxDateBox"
          options={{
            value: startDate,
            onValueChanged: (e: any) => setStartDate(e.value),
            placeholder: startDateCaption,
          }}
        />
        <Item
          location="after"
          locateInMenu="auto"
          widget="dxDateBox"
          options={{
            value: endDate,
            onValueChanged: (e: any) => setEndDate(e.value),
            placeholder: endDateCaption,
          }}
        />
        <Item
          location="after"
          locateInMenu="auto"
          widget="dxSelectBox"
          options={{
            items: [last1Day, last7Days, last30Days, last90Days, last12Months],
            value: presetDateRange,
            onValueChanged: handlePresetDateRangeChange,
            placeholder: selectDateRange,
          }}
        />
        <Item
          location="after"
          locateInMenu="auto"
          render={() => (
            <DropDownBox
              dataSource={ctcList}
              valueExpr="name"
              displayExpr="name"
              value={selectedCtcs}
              placeholder={selectCTC}
              width="100%"
            >
              <List
                searchEnabled
                searchExpr="name"
                dataSource={ctcList}
                keyExpr="name"
                displayExpr="name"
                selectedItemKeys={selectedCtcs}
                selectionMode="all"
                showSelectionControls
                selectByClick
                selectAllMode={"allPages"}
                onOptionChanged={onSelectedCtcChange}
              />
            </DropDownBox>
          )}
        />
        <Item
          location="after"
          locateInMenu="auto"
          widget="dxButton"
          showText="inMenu"
        >
          <Button
            text={refresh}
            icon="refresh"
            stylingMode="text"
            onClick={refreshReports}
          />
        </Item>
      </Toolbar>
      <div className="report-section">
        <div className="inspection-report-piechart">
          <PieChart
            id="overall-pass-fail"
            dataSource={overallStats}
            title="Overall Pass/Fail Counts"
          >
            <Series argumentField="status" valueField="count">
              <Label visible={true}>
                <Format type="fixedPoint" precision={0} />
              </Label>
            </Series>
            <Legend visible={true} />
            <Export enabled />
          </PieChart>
        </div>
        <div className="inspection-report-chart">
          <Chart id="inspection-pass-fail" dataSource={sortedInspectionStats}>
            <Title text="Inspection-wise Pass/Fail Count" />
            <CommonSeriesSettings argumentField="name" type="stackedbar" />
            <Series valueField="passed" name={passedStatus} color="#00B578" />
            <Series valueField="failed" name={failedStatus} color="#f44336" />
            <ArgumentAxis>
              <Label overlappingBehavior="stagger" />
            </ArgumentAxis>
            <ValueAxis>
              <Label>
                <Format type="fixedPoint" precision={0} />
              </Label>
              <Title text="Inspections Count" />
            </ValueAxis>
            <Legend
              verticalAlignment="top"
              horizontalAlignment="center"
              itemTextPosition="right"
            />
            <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
            <Export enabled />
          </Chart>
        </div>
      </div>
      <br />
      <div className="datagrid-section">
        <DataGrid
          dataSource={inspections}
          rowAlternationEnabled={true}
          showBorders={true}
          width="100%"
          onContentReady={onContentReady}
          onRowPrepared={rowPrepared}
        >
          <Column dataField="name" caption={passFailPercentageofQuestions} />
          <MasterDetail
            enabled={true}
            component={(props) => (
              <DetailTemplate
                {...props}
                startDate={startDate}
                endDate={endDate}
                selectedCtcs={selectedCtcs}
              />
            )}
          />
          <Pager
            allowedPageSizes={[10, 25, 50, 100]}
            showPageSizeSelector={true}
          />
          <Paging defaultPageSize={25} />
        </DataGrid>
      </div>
    </div>
  );
}
