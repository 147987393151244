// DynamicForm.tsx
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Form, { SimpleItem, GroupItem, Label } from 'devextreme-react/form';
import { RequiredRule } from 'devextreme-react/form';
import { Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/cjs/toolbar';
import { ButtonTypes } from 'devextreme-react/cjs/button';
import { IInspectionCreationForm, InspectionFormData, QuestionType } from '../../../../../interfaces';
import ISafeFileUploader from '../../../../../components/Customizatons/FileUploadComponent/FileUploadComponent';

interface DynamicFormProps {
    formData: InspectionFormData;
    initialValues?: IInspectionCreationForm | null
}
const yesNoOptions = [
    { id: 0, text: 'Yes' },
    { id: 1, text: 'No' },
    { id: 2, text: 'NA' }
]
const passFailOptions = [
    { id: 0, text: 'Pass' },
    { id: 1, text: 'Fail' },
    { id: 2, text: 'NA' }
]
const DynamicForm: React.FC<DynamicFormProps> = ({ formData, initialValues }) => {

    const formRef = useRef<Form>(null);

    const mapCreationFormToState = (creationForm: IInspectionCreationForm) => {
        const formState = {
            id: creationForm.inspectionId, // Map inspectionId to id
            additionalNotes: creationForm.additionalNotes,
            // Map details to a format that can be understood by the form,
            // where the keys are 'details.{id}' and the values are the actual data
            details: creationForm.details.reduce((detailsState, detail) => ({
                ...detailsState,
                [`${detail.inspectionDetailId}`]: detail.data,
            }), {}),
            images:creationForm.images,
            // Map questions in a similar way as details
            questions: creationForm.answers.reduce((questionsState, answer) => ({
                ...questionsState,
                [`${answer.inspectionQuestionId}`]: answer.isNotApplicable ? 2 : (answer.isAcceptable ? 0 : 1),
            }), {}),
        };
        return formState;
    };
    const [formValues, setFormValues] = useState<any>(!!initialValues ? mapCreationFormToState(initialValues) : { id: formData.id });
    

    const questionEditiorOptions = (type: QuestionType) => {
        let options; //default
        switch (type) {
            case QuestionType.PassOrFail:
                options = passFailOptions;
                break;
            case QuestionType.YesOrNo:
                options = yesNoOptions;
                break;
            default:
                options = yesNoOptions;
                break;
        }
        return { items: options, valueChangeEvent: 'change', layout: 'horizontal', valueExpr: "id", displayExpr: "text" };
    }
    
    return (
        <>
            <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>

                <Form
                    colCount={1}
                    formData={formValues}
                    ref={formRef}
                // ... handle field data changes if needed
                >
                    {formData.details.map((detail) => (
                        <SimpleItem key={detail.id} dataField={`details.${detail.id}`} editorType="dxTextBox" label={{ text: detail.name }}
                            editorOptions={{
                                placeholder: "Type here...",
                                showClearButton: true,
                                disabled:true
                            }}>
                            <RequiredRule />
                        </SimpleItem>
                    ))}
                    {formData.questions.map((question) => (
                        <GroupItem key={question.id}>
                            <SimpleItem dataField={`questions.${question.id}`} editorType="dxRadioGroup" 
                                editorOptions={{
                                    ...questionEditiorOptions(question.type),
                                    disabled:true
                                }}
                            >
                                <Label text={question.question} />
                                <RequiredRule />
                            </SimpleItem>
                        </GroupItem>
                    ))}

                    <SimpleItem dataField="additionalNotes" editorType="dxTextArea" label={{ text: "Additional Notes" }} editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true
                    }}>
                    </SimpleItem>


                </Form>
                <ISafeFileUploader initialFileIds={[...initialValues?.images ||[]]} uploadedFileIds={() =>{}} isDisabled />

            </div>
        </>


    );
};

export default DynamicForm;
