import React, { useCallback, useEffect, useState } from 'react';
import './region-management.scss';
import { DataGrid, Column, Paging, Pager, SearchPanel, DataGridTypes, Selection } from 'devextreme-react/data-grid';
import { Button, Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/toolbar'
import { locationService } from '../../../../api';
import { ISafeConfirmationButton } from '../../../../components/Customizatons';
import { useLoad, useRegion, useToast } from '../../../../contexts';
import { IApiResponse, IRegion } from '../../../../interfaces';
import AddRegionDialog from './dialogs/add-region';


const RegionManagement: React.FC = () => {
  const { showLoader } = useLoad();
  const { showErrorToast, showSuccessToast , showWarningToast} = useToast();
  const { region,setRegion, fetchRegions} = useRegion(); // Use the region value from context
  const [isAddRegionDialogVisible, setisAddRegionDialogVisible] = useState(false);
  const [currentSelectedRegion, setcurrentSelectedRegion] = useState<IRegion | null>(null);
  const [allRegions, setAllRegions] = useState<IRegion[]>([]);
  const toggleRegionsDialog = useCallback(() => {
    setisAddRegionDialogVisible(prevState => !prevState);
  }, []);
  const fetchAllRegions = useCallback(async () => {
    showLoader(true);
    try {
      const response = await locationService.getAll();
      if (response.success) {
        setAllRegions(response.data);
      } else {
        console.error(response.errors);
        showErrorToast('Error fetching Locations');
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      showLoader(false);
    }
  }, []);

  useEffect(() => {
    fetchAllRegions();
  }, []);
  const addNewRegion = () => {
    setcurrentSelectedRegion(null);
    setisAddRegionDialogVisible(!isAddRegionDialogVisible);
  };

  const handleAddEditRegion = useCallback(async (region: IRegion) => {
    showLoader(true);
    try {
      let response: IApiResponse<IRegion>;
      if (region.id > 0) {
        // Update existing region
        response = await locationService.update(region.id.toString(), region);
        if (response.success) {
          setAllRegions(currentRegions =>
            currentRegions.map(a =>
              a.id === region.id ? response.data : a // Update the specific region
            )
          );
          showSuccessToast('Location updated successfully!');
          setisAddRegionDialogVisible(false);
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to update the Location: ${response.errors.join('; ')}`);
        }
      } else {
        // Create new Region
        response = await locationService.create(region);
        if (response.success) {
          setAllRegions(currentRegions => [...currentRegions, response.data]); // Add new Location
          showSuccessToast('Location added successfully!');
          setisAddRegionDialogVisible(false);
        } else {
          console.error(response.errors);
          showErrorToast(`Unable to add the Location: ${response.errors.join('; ')}`);
        }
      }
    } catch (error) {
      console.error(error);
      showErrorToast('Network or server error');
    } finally {
      fetchAllRegions();
      fetchRegions();
      showLoader(false);
    }
  }, [showLoader, showErrorToast, showSuccessToast]);

  const handleDeleteRegion = useCallback((regionId: number) => {
    
    showLoader(true);
    locationService.delete(regionId.toString()).then(response => {
      if (response.success) {
        // Remove the Region from the list
        setAllRegions(currentRegions =>
          currentRegions.filter(location => location.id !== regionId)
        );
        showSuccessToast('Location deleted successfully!');
        fetchRegions();
        if(Number(region) == regionId){
          setRegion('');
        }
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to delete the Location: ${response.errors.join('; ')}`);
      }
      showLoader(false);
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast]);
  const handleActivateRegion = useCallback((regionId: number, isActive: boolean) => {
    showLoader(true);
    locationService.activate(regionId.toString(), !isActive).then(response => {
      if (response.success) {
        setAllRegions(currentRegions => {
          var match = currentRegions.find(a => a.id == regionId);
          if (match) match.isActive = !match?.isActive
          return currentRegions;
        }
        );
        
        showSuccessToast(`Location ${isActive ? 'Disabled' : 'Enabled'} successfully!`);
        fetchAllRegions();
        fetchRegions();
        if(Number(region) == regionId){
          setRegion('');
        }
      } else {
        console.error(response.errors);
        showErrorToast(`Unable to Enable/Disable the Location: ${response.errors.join('; ')}`);
      }
      
    }).catch(error => {
      console.error(error);
      showErrorToast('Network or server error');
      
    }).finally(() =>{
      showLoader(false);
    });
  }, [showLoader, showErrorToast, showSuccessToast, allRegions]);
  const renderTitle = useCallback(() => {
    return <p className='section-title'><b>Locations</b></p>;
  }, []);
  const addButtonOptions = {
    icon: 'add',
    text: 'Add New',
    stylingMode: "outlined",
    onClick: addNewRegion
  }


  const onCellClick = useCallback((data: DataGridTypes.CellClickEvent) => {
    setcurrentSelectedRegion(data.data);
  }, []);
  const cellRender = (data: DataGridTypes.ColumnCellTemplateData) => {
    return <div>
      <Button
        text="Edit"
        type="default"
        stylingMode="text"
        onClick={() => {
          setcurrentSelectedRegion(data.data);
          toggleRegionsDialog();
        }}
        disabled={!data.data.isActive}
      />
      <ISafeConfirmationButton
        text={data.data.isActive ? 'Disable' : 'Enable'}
        type="default"
        componentName={RegionManagement.name}
        stylingMode="text"
        item={data.data}
        onConfirm={() => handleActivateRegion(data.data.id, data.data.isActive)}
      />
      <ISafeConfirmationButton
        disabled={true}
        text="Delete"
        type="default"
        componentName={RegionManagement.name}
        stylingMode="text"
        item={data.data}
        onConfirm={() => handleDeleteRegion(data.data.id)}
      />
    </div>
  };
  const rowPrepared = useCallback((e: DataGridTypes.RowPreparedEvent) => {
    if (e.rowType === "data") {
      if (!e.data.isActive) {
        e.rowElement.classList.add("inactive-row");
      }
    }
  }, []);
  return (<>
    <div className='region-management-container'>
      <Toolbar className='section-toolbar'>
        <Item
          location='before'
          render={renderTitle}
        ></Item>
        <Item
          location='after'
          widget="dxButton"
          options={addButtonOptions}
        ></Item>
      </Toolbar>
      <DataGrid
        dataSource={allRegions}
        keyExpr="id"
        showBorders={true}
        allowColumnReordering={true}
        height={'80vh'}
        onCellClick={onCellClick}
        onRowPrepared={rowPrepared}>
        <Selection mode="single" />
        <Paging defaultPageSize={10} />
        <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showNavigationButtons displayMode='compact' showInfo={true} />

        <Column dataField="name" caption="Name" />
        <Column dataField="code" caption="Code" />
        <Column dataField="createdDate" caption="Created Date" dataType="date" format={"dd MMM yyyy"} />

        <Column cssClass='actions-column' caption="Actions" cellRender={cellRender}>

        </Column>
        <SearchPanel placeholder={'Search here ...'} width={'auto'} visible={true} searchVisibleColumnsOnly />
      </DataGrid>
    </div>
    {isAddRegionDialogVisible && <AddRegionDialog
      visible={isAddRegionDialogVisible}
      onHiding={toggleRegionsDialog}
      onApply={handleAddEditRegion}
      region={currentSelectedRegion}
    />
    }
  </>
  );
};

export default RegionManagement;
