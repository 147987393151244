import { IApiResponse, IIncidentCreationForm, IIncidentUpdationForm, IPreworkInspection } from '../../interfaces';
import { ApiService } from '../ApiService';
import axiosInstance from '../AxiosService';
import { AxiosResponse } from 'axios';


const endpoint=  'Incident'
class IncidentService extends ApiService<IIncidentCreationForm> {
  constructor() {
    super(endpoint);
  }
  getIncidentUpdateInfor(id:number,params = {}): Promise<IApiResponse<IIncidentUpdationForm>> {
    return axiosInstance.get(`${endpoint}/${id}/Status`, { params })
      .then((response: AxiosResponse) => this.handleIncidentUpdateInfoResponse(response));
  }
  updateIncident(payload: IIncidentUpdationForm): Promise<IApiResponse<IIncidentUpdationForm>> {
    return axiosInstance.post(`${endpoint}/Status`,payload)
      .then((response: AxiosResponse) => this.handleIncidentUpdateInfoResponse(response));
  }
  private handleIncidentUpdateInfoResponse(response: AxiosResponse<IApiResponse<IIncidentUpdationForm>>): IApiResponse<IIncidentUpdationForm> {
    return response.data;
  }
  // Additional methods specific to the User model can be added here
}

export const incidentService = new IncidentService();