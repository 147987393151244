import InspectionAnalysis from './pages/inspection-analysis/inspection-analysis';
import InspectionManagement from './pages/inspection-management/inspection-management';
import { ISafeTabPanel } from '../../components/Customizatons';
import InspectionReports from './pages/inspection-reports/inspection-reports';
import { TAB_TITLE } from '../../utils/constants';
import './inspections.scss';

const { inspectionsManagement, inspectionReports, analysis } = TAB_TITLE

const inspectionTabs = [
    { id: 0, title: inspectionsManagement, icon: 'mediumiconslayout', component: InspectionManagement },
    { id: 1, title: inspectionReports, icon: 'taskcomplete', component: InspectionReports },
    { id: 2, title: analysis, icon: 'datausage', component: InspectionAnalysis }
];

export default function Inspections() {
    return (
        <div className='inspection-home'>
           <ISafeTabPanel tabs={inspectionTabs} />
        </div>
    );
}
