import { Button, TabPanel, Toolbar } from "devextreme-react"
import { IIncidentCreationForm, IIncidentUpdationForm, IncidentStatus, IncidentType, TabItem } from "../../../../interfaces";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Item } from "devextreme-react/cjs/toolbar";
import { DataGrid, Column, Paging, Pager, SearchPanel, DataGridTypes, Selection } from 'devextreme-react/data-grid';
import { incidentService } from "../../../../api";
import inspections from "../../../inspections/inspections";
import { useLoad, useToast } from "../../../../contexts";
import { ISafeIncidentStatusButton } from "../../../../components/Customizatons";
import EditIncidentDialog from "./dialogs/edit-incident";

const tabs: TabItem[] = [
    { id: 0, title: 'All' },
    { id: 1, title: 'Closed' },
    { id: 2, title: 'Under Investigation' },
    { id: 3, title: 'New' }
];
export default function IncidentsManagement() {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [incidents, setIncidents] = useState<IIncidentCreationForm[]>([]);

    const { showLoader } = useLoad();
    const { showErrorToast, showSuccessToast } = useToast();
    const [isEditIncidentDialogVisible, setIsEditIncidentDialogVisible] = useState(false);
    const [currentSelectedIncident, setCurrentSelectedIncident] = useState<IIncidentCreationForm | null>(null);
    const toggleIncidentDialog = useCallback(() => {
        setIsEditIncidentDialogVisible(prevState => !prevState);
    }, []);
    const fetchIncidents = useCallback(async () => {
        showLoader(true);
        try {
            const response = await incidentService.getAllByRegion();
            if (response.success) {
                const sortedIncidents = response.data.sort((a, b) =>
                    (b.createdDate ? new Date(b.createdDate).getTime() : new Date().getTime()) - (a.createdDate ? new Date(a.createdDate).getTime() : new Date().getTime())
                );
                setIncidents(sortedIncidents);
            } else {
                console.error(response.errors);
            }
        } catch (error) {
            console.error(error);
        } finally {
            showLoader(false);
        }
    }, [showLoader]);
    const handleEditIncident = useCallback(async (incident: IIncidentUpdationForm) => {
        showLoader(true);
        try {
          const response = await incidentService.updateIncident(incident);
          if(response.success){
            toggleIncidentDialog();
            fetchIncidents();
          }
          else
            showErrorToast('Unable to update the Incident...');
        } catch (error) {
          console.error(error);
          showErrorToast('Network or server error');
        } finally {
          showLoader(false);
        }
      }, [showLoader, showErrorToast, showSuccessToast]);
    useEffect(() => {
        fetchIncidents();
    }, [fetchIncidents]);

    const handleSelectihandleFormChanged = (e: any) => {
        setSelectedTabIndex(e.component.option('selectedIndex'));
    };
    const renderTitle = useCallback(() => {
        return <p className='section-title'><b>Incidents Management</b></p>;
    }, []);
    const cellRender = (data: DataGridTypes.ColumnCellTemplateData) => {
        return <div>

            <Button
                text={'Edit'}
                type="default"
                stylingMode="text"
                onClick={() => {
                    setCurrentSelectedIncident(data.data);
                    toggleIncidentDialog();
                  }}
            />
        </div>
    };

    const colleagueNameRenderer = useCallback((data: DataGridTypes.ColumnCellTemplateData) => {
        var incident = data.data as IIncidentCreationForm;
        return <p>{incident.generalIncident?.affectedCollegue?.[0]?.name || incident.vehicleIncident?.associatedConvergentColleague?.[0]?.name || 'N/A'}</p>
    }, [incidents]);


    const statusRenderer = useCallback((data: DataGridTypes.ColumnCellTemplateData) => {
        var incident = data.data as IIncidentCreationForm;
        let buttonText = '';
        let buttonStyle = {};
        let icon = ''
        switch (incident.incidentStatus) {
            case IncidentStatus.REPORTED:
                buttonText = 'New';
                buttonStyle = { color: '#03a9f4', borderColor: '#03a9f4' };
                break;
            case IncidentStatus.UNDER_INVESTIGATION:
                buttonText = 'Under Investigation';
                icon = 'clock'
                buttonStyle = { color: 'grey', borderColor: 'grey' };
                break;
            case IncidentStatus.CLOSED:
                buttonText = 'Closed';
                icon = 'check'
                buttonStyle = { color: '#00B578', borderColor: '#00B578' };
                break;
            default:
                buttonText = incident.incidentStatus;
                buttonStyle = { color: 'black', borderColor: 'black' };
                break;
        }

        return (
            <ISafeIncidentStatusButton
            componentName={IncidentsManagement.name}
            onStatusChange={(status:IncidentStatus) => onIncidentUpdated({
                ...incident,
                incidentStatus:status,
                vehicleIncident: incident.incidentType == IncidentType.GENERAL ? undefined:incident.vehicleIncident,
                generalIncident: incident.incidentType == IncidentType.VEHICLE ? undefined:incident.generalIncident,
            })}
            item={incident}
                text={buttonText}
                icon={icon}
                type="default"
                stylingMode="outlined"
                style={buttonStyle}
            />
        );
    }, [incidents]);
    const onIncidentUpdated = async (payload: IIncidentCreationForm) => {
        console.log('Incident Updated:', payload);
        const submittedIncident = await incidentService.update(payload.id.toString(),payload);
        if (submittedIncident.success) {
            fetchIncidents();
        }
    };
    const filteredIncidents = useMemo(() => {
        switch (selectedTabIndex) {
            case 0:
                return incidents;
            case 1:
                return incidents.filter(i => i.incidentStatus == IncidentStatus.CLOSED);
            case 2:
                return incidents.filter(i => i.incidentStatus == IncidentStatus.UNDER_INVESTIGATION);
            case 3:
                return incidents.filter(i => i.incidentStatus == IncidentStatus.REPORTED);
            default:
                return incidents;
        }
    }, [incidents, selectedTabIndex]);
    return <div className="incident-management">
        <Toolbar className='section-toolbar'>
            <Item
                location='before'
                render={renderTitle}
            ></Item>

        </Toolbar>
        <TabPanel
            key={tabs[selectedTabIndex].title}
            className="dx-theme-background-color"
            width={"100%"}
            height={'100%'}
            animationEnabled={true}
            dataSource={tabs}
            tabsPosition={'top'}
            stylingMode={'primary'}
            selectedIndex={selectedTabIndex}
            onSelectionChanged={handleSelectihandleFormChanged}
        />
        <DataGrid
            dataSource={filteredIncidents}
            keyExpr="id"
            showBorders={true}
            allowColumnReordering={true}
            height={'75vh'}
        // onRowPrepared={rowPrepared}
        >
            <Selection mode="single" />
            <Paging defaultPageSize={10} />
            <Pager visible={true} showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showNavigationButtons displayMode='compact' showInfo={true} />

            <Column caption="Name"
                cellRender={colleagueNameRenderer}
            />
            <Column dataField="createdDate" caption="Reported Date" dataType="date" format={"dd MMM yyyy,hh:mm"} />
            <Column dataField="incidentDate" caption="Incident Date" dataType="date" format={"dd MMM yyyy,hh:mm"} />
            <Column caption="Status"
                cellRender={statusRenderer}
            />

            <Column cssClass='actions-column' caption="Actions" cellRender={cellRender}>

            </Column>
            <SearchPanel placeholder={'Search here ...'} width={'auto'} visible={true} />
        </DataGrid>
        {isEditIncidentDialogVisible && <EditIncidentDialog
      visible={isEditIncidentDialogVisible}
      onHiding={toggleIncidentDialog}
      onApply={handleEditIncident}
      incident={currentSelectedIncident}
    />
    }
    </div>
}