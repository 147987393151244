import React, { useState } from 'react';
import { Button, IButtonOptions } from 'devextreme-react/button';
import { IPopoverOptions, Popover, ToolbarItem } from 'devextreme-react/popover';
import { IBase } from '../../../interfaces'
import './ButtonWithConfirmation.scss';
interface ButtonWithConfirmationProps extends IButtonOptions {
    item?: IBase;
    componentName:string;
    onConfirm: () => void;
}

const animationConfig: IPopoverOptions['animation'] = {
  show: {
    type: 'pop',
    from: {
      scale: 0,
    },
    to: {
      scale: 1,
    },
  },
  hide: {
    type: 'fade',
    from: 1,
    to: 0,
  },
};
const ButtonWithConfirmation: React.FC<ButtonWithConfirmationProps> = ({
    item,
    onConfirm,
    componentName,
    ...props
}) => {
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const togglePopover = () => setIsPopoverVisible(!isPopoverVisible);

    const handleConfirmClick = () => {
        onConfirm();
        setIsPopoverVisible(false);
    };

    const noButtonOptions = {
        text: 'No',
        type: 'default',
        stylingMode: 'outlined',
        onClick: togglePopover,
    };

    const yesButtonOptions = {
        text: 'Yes',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleConfirmClick,
    };
    return (
        <>
            <Button
                id={`${componentName}-${props.text}-${item?.id}`}
                onClick={togglePopover}
                {...props}
            />
            <Popover
                target={`#${componentName}-${props.text}-${item?.id}`}
                visible={isPopoverVisible}
                showEvent="click"
                onHiding={togglePopover}
                position="bottom"
                width={250}
                animation={animationConfig}
                className='confirmation-popover'
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>
                        <i className={`dx-icon-errorcircle disable-confirmation`}></i>
                    </span>
                    <span>{`Are you sure you want to ${props.text} ${item?.name ? item?.name :''}?`} </span>
                </div>

                <ToolbarItem
                    options={noButtonOptions}
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                />
                <ToolbarItem
                    options={yesButtonOptions}
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                />
            </Popover>
        </>
    );
};

export default ButtonWithConfirmation;
