import React, { useEffect, useRef, useState } from 'react';
import { ButtonGroup, Form, Popup, TextBox } from 'devextreme-react';
import { ToolbarItem } from 'devextreme-react/popup';
import { SimpleItem, RequiredRule, Label } from 'devextreme-react/form';
import { IPpe } from '../../../../../interfaces'
import { SelectionChangedEvent } from 'devextreme/ui/button_group';
import { IHseAcknowledgement } from '../../../../../interfaces'

export interface AddHseDialogProps {
    visible: boolean;
    hse: IHseAcknowledgement | null;
    onHiding: () => void;
    onApply: (ppe: IHseAcknowledgement) => void;
}

const AddHseDialog: React.FC<AddHseDialogProps> = ({
    visible,
    onHiding,
    onApply,
    hse
}) => {
    const [currentHse, setCurrentHse] = useState<IHseAcknowledgement>({
        id: 0,
        name: '',
        createdDate: new Date(),
        isActive: true,
        regionId: Number(localStorage.getItem('region'))
    });

    const formRef = useRef<Form>(null);

    useEffect(() => {
        if (hse) {
            setCurrentHse({...hse});
        }
    }, [hse]);

    const handleApply = () => {
        const result = formRef.current?.instance.validate();
        if (result?.isValid) {
            var trimmedData = {...currentHse, name:currentHse.name?.trim()}
            onApply(trimmedData);
        }
    };

    const saveButtonOptions = {
        text: 'Save',
        type: 'default',
        stylingMode: 'contained',
        onClick: handleApply,
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'default',
        stylingMode: 'outlined',
        onClick: onHiding,
    };


    const handleClearName = () => {
        setCurrentHse(prevState => ({ ...prevState, name: '' }));
    };
    return (
        <Popup
            width={'25%'}
            height={'100%'}
            visible={visible}
            onHiding={onHiding}
            hideOnOutsideClick={false}
            position={'right'}
            title={currentHse?.id > 0 ? 'Edit HSE' : 'Add HSE'}
        >
            <Form
                formData={currentHse}
                ref={formRef}
            >
                <SimpleItem 
                editorType="dxTextArea"
                dataField="name"
                    editorOptions={{
                        placeholder: "Type here...",
                        showClearButton: true,
                        autoResizeEnabled:true,
                        buttons: [{
                            name: 'trash',
                            location: 'after',
                            options: {
                                stylingMode: 'text',
                                icon: 'trash',
                                onClick: handleClearName,
                            },
                        }],
                    }}>
                    <Label text="HSE Name" />
                    <RequiredRule message="HSE Name is mandatory" />
                </SimpleItem>
                
            </Form>
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={cancelButtonOptions}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="top"
                location="after"
                options={saveButtonOptions}
            />

        </Popup>
    );
};

export default AddHseDialog;
