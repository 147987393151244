// axiosInstance.ts
import axios from "axios";

// Token provider function placeholder
let tokenProvider: () => Promise<string | null> = async () => null;
const baseURL = localStorage.getItem("iSafe2BaseUrl")?.toString();

const axiosInstance = axios.create({
  baseURL: `${baseURL}/api/`,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await tokenProvider();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      // Handle case where token is not available
      // Redirect to logout or perform other actions
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to update the token provider
export const updateTokenProvider = (
  newTokenProvider: () => Promise<string | null>
) => {
  tokenProvider = newTokenProvider;
};

export default axiosInstance;
