import { AxiosResponse } from 'axios';
import { IApiResponse, ICTCInfo, ICareWorkOrder, ID65WorkOrder, IRegion, ISpectrumJob, IUser } from '../../interfaces';
import { ApiService } from '../ApiService';
import axiosInstance from '../AxiosService';


const endpoint=  'ERP'
class ErpService extends ApiService<IUser> {
  constructor() {
    super(endpoint);
  }
  getAllUsers(name:string,params = {}): Promise<IApiResponse<IUser[]>> {
    return axiosInstance.get(`${endpoint}/Colleague?name=${name}`, { params })
      .then((response: AxiosResponse) => this.handleUsersResponse(response));
  }
  getICareWorkOrders(woid?:string, params = {}): Promise<IApiResponse<ICareWorkOrder[]>> {
    //optinally pass the woid as query parameter if the value is given
    var query = '';
    if(woid){
      query = `?WOId=${woid}`;
    }
    return axiosInstance.get(`${endpoint}/ICareWorkOrder${query}`, { params })
      .then((response: AxiosResponse) => this.handleICareWorkOrdersResponse(response));
  }
  getISpectrumJobs(jobId?:string,ctc?:string,params = {}): Promise<IApiResponse<ISpectrumJob[]>> {
    //optinally pass the jobId and ctc as query parameter if the value is given
    var query = '';
    if(jobId){
      query = `?JobId=${jobId}`;
    }
    if(ctc){
      //if jobId is already present then append the query with &CTC
      if(query){
        query = query + `&CTC=${ctc}`;
      }
      query =  `?CTC=${ctc}`;
    }
    return axiosInstance.get(`${endpoint}/Specturm${query}`, { params })
      .then((response: AxiosResponse) => this.handleISpectrumJobsResponse(response));
  }

  getD65WorkOrders(woid?:string,serviceAccountNumber?:string, params = {}): Promise<IApiResponse<ID65WorkOrder[]>> {
    //optinally pass the woid as query parameter if the value is given
    var query = '';
    if(woid){
      query = `?WOId=${woid}`;
    }
    if(serviceAccountNumber){
      //if woid is already present then append the query with &serviceAccountNumber
      if(query){
        query = query + `&serviceAccountNumber=${serviceAccountNumber}`;
      }
      query =  `?serviceAccountNumber=${serviceAccountNumber}`;
    }
    return axiosInstance.get(`${endpoint}/D365${query}`, { params })
      .then((response: AxiosResponse) => this.handleD365WorkOrders(response));
  }
  getAllCTCs(): Promise<IApiResponse<ICTCInfo[]>> {
    return axiosInstance.get(`${endpoint}/CTCs`)
      .then((response: AxiosResponse) => this.handleCTCssResponse(response));
  }
  private handleD365WorkOrders(response: AxiosResponse<IApiResponse<ID65WorkOrder[]>>) : IApiResponse<ID65WorkOrder[]>{
    return response.data;
  }

  private handleISpectrumJobsResponse(response: AxiosResponse<IApiResponse<ISpectrumJob[]>>): IApiResponse<ISpectrumJob[]> {
    return response.data;
  }
  private handleICareWorkOrdersResponse(response: AxiosResponse<IApiResponse<ICareWorkOrder[]>>): IApiResponse<ICareWorkOrder[]> {
    return response.data;
  }
  private handleUsersResponse(response: AxiosResponse<IApiResponse<IUser[]>>): IApiResponse<IUser[]> {
    return response.data;
  }
  private handleCTCssResponse(response: AxiosResponse<IApiResponse<ICTCInfo[]>>): IApiResponse<ICTCInfo[]> {
    return response.data;
  }
  // Additional methods specific to the User model can be added here
}

export const erpService = new ErpService();