import { withNavigationWatcher } from "./contexts";
import { TasksPage, JhaPage, ChaPage, IncidentsPage, InspectionsPage } from "./pages";
import Admin from "./pages/admin/admin";
import NotAuthorized from "./components/NotAuthorized/NotAuthorized";
import AuthGuard from "./components/AuthGuard/AuthGuard";

type RouteElementProps = {
  isAuthorized: boolean;
};

const routes = [
  {
    path: "/tasks",
    element: (props: RouteElementProps) =>
      <AuthGuard component={props.isAuthorized ? TasksPage : NotAuthorized} isAuthorized={props.isAuthorized} />,
  },
  {
    path: "/jha",
    element: (props: RouteElementProps) =>
      <AuthGuard component={props.isAuthorized ? JhaPage : NotAuthorized} isAuthorized={props.isAuthorized} />,
  },
  {
    path: "/cha",
    element: (props: RouteElementProps) =>
      <AuthGuard component={props.isAuthorized ? ChaPage : NotAuthorized} isAuthorized={props.isAuthorized} />,
  },
  {
    path: "/incidents",
    element: (props: RouteElementProps) =>
      <AuthGuard component={props.isAuthorized ? IncidentsPage : NotAuthorized} isAuthorized={props.isAuthorized} />,
  },
  {
    path: "/inspections",
    element: (props: RouteElementProps) =>
      <AuthGuard component={props.isAuthorized ? InspectionsPage : NotAuthorized} isAuthorized={props.isAuthorized} />,
  },
  {
    path: "/admin",
    element: (props: RouteElementProps) =>
      <AuthGuard component={props.isAuthorized ? Admin : NotAuthorized} isAuthorized={props.isAuthorized} />,
  },
  {
    path: "/not-authorized",
    element: NotAuthorized,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path)
  };
});